/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AttendeeRole {
  Admin = "Admin",
  Guest = "Guest",
}

export enum JsonNullValueFilter {
  AnyNull = "AnyNull",
  DbNull = "DbNull",
  JsonNull = "JsonNull",
}

export enum Language {
  English = "English",
  French = "French",
  German = "German",
}

export enum NullableJsonNullValueInput {
  DbNull = "DbNull",
  JsonNull = "JsonNull",
}

export enum QueryMode {
  default = "default",
  insensitive = "insensitive",
}

export enum ReactionType {
  Dislike = "Dislike",
  Like = "Like",
}

export enum SortOrder {
  asc = "asc",
  desc = "desc",
}

export enum Theme {
  EPFL = "EPFL",
  SpeakUp = "SpeakUp",
  UniGE = "UniGE",
  UniNE = "UniNE",
  Unil = "Unil",
}

export interface BoolFieldUpdateOperationsInput {
  set?: boolean | null;
}

export interface BoolFilter {
  equals?: boolean | null;
  not?: NestedBoolFilter | null;
}

export interface CommentCreateInput {
  id?: string | null;
  content: string;
  isPublished?: boolean | null;
  nickName?: string | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  modifiedBy?: string | null;
  post: PostCreateNestedOneWithoutCommentsInput;
  reactions?: CommentReactionCreateNestedManyWithoutCommentInput | null;
  createdBy: UserCreateNestedOneWithoutCommentsInput;
}

export interface CommentCreateManyCreatedByInput {
  id?: string | null;
  content: string;
  isPublished?: boolean | null;
  postId: string;
  nickName?: string | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  modifiedBy?: string | null;
}

export interface CommentCreateManyCreatedByInputEnvelope {
  data?: CommentCreateManyCreatedByInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface CommentCreateManyPostInput {
  id?: string | null;
  content: string;
  isPublished?: boolean | null;
  nickName?: string | null;
  userId: string;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  modifiedBy?: string | null;
}

export interface CommentCreateManyPostInputEnvelope {
  data?: CommentCreateManyPostInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface CommentCreateNestedManyWithoutCreatedByInput {
  create?: CommentCreateWithoutCreatedByInput[] | null;
  connectOrCreate?: CommentCreateOrConnectWithoutCreatedByInput[] | null;
  createMany?: CommentCreateManyCreatedByInputEnvelope | null;
  connect?: CommentWhereUniqueInput[] | null;
}

export interface CommentCreateNestedManyWithoutPostInput {
  create?: CommentCreateWithoutPostInput[] | null;
  connectOrCreate?: CommentCreateOrConnectWithoutPostInput[] | null;
  createMany?: CommentCreateManyPostInputEnvelope | null;
  connect?: CommentWhereUniqueInput[] | null;
}

export interface CommentCreateNestedOneWithoutReactionsInput {
  create?: CommentCreateWithoutReactionsInput | null;
  connectOrCreate?: CommentCreateOrConnectWithoutReactionsInput | null;
  connect?: CommentWhereUniqueInput | null;
}

export interface CommentCreateOrConnectWithoutCreatedByInput {
  where: CommentWhereUniqueInput;
  create: CommentCreateWithoutCreatedByInput;
}

export interface CommentCreateOrConnectWithoutPostInput {
  where: CommentWhereUniqueInput;
  create: CommentCreateWithoutPostInput;
}

export interface CommentCreateOrConnectWithoutReactionsInput {
  where: CommentWhereUniqueInput;
  create: CommentCreateWithoutReactionsInput;
}

export interface CommentCreateWithoutCreatedByInput {
  id?: string | null;
  content: string;
  isPublished?: boolean | null;
  nickName?: string | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  modifiedBy?: string | null;
  post: PostCreateNestedOneWithoutCommentsInput;
  reactions?: CommentReactionCreateNestedManyWithoutCommentInput | null;
}

export interface CommentCreateWithoutPostInput {
  id?: string | null;
  content: string;
  isPublished?: boolean | null;
  nickName?: string | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  modifiedBy?: string | null;
  reactions?: CommentReactionCreateNestedManyWithoutCommentInput | null;
  createdBy: UserCreateNestedOneWithoutCommentsInput;
}

export interface CommentCreateWithoutReactionsInput {
  id?: string | null;
  content: string;
  isPublished?: boolean | null;
  nickName?: string | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  modifiedBy?: string | null;
  post: PostCreateNestedOneWithoutCommentsInput;
  createdBy: UserCreateNestedOneWithoutCommentsInput;
}

export interface CommentListRelationFilter {
  every?: CommentWhereInput | null;
  some?: CommentWhereInput | null;
  none?: CommentWhereInput | null;
}

export interface CommentOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface CommentReactionCreateInput {
  id: string;
  reactionType?: ReactionType | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  modifiedBy?: string | null;
  comment?: CommentCreateNestedOneWithoutReactionsInput | null;
  createdBy: UserCreateNestedOneWithoutCommentReactionsInput;
}

export interface CommentReactionCreateManyCommentInput {
  id: string;
  reactionType?: ReactionType | null;
  userId: string;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  modifiedBy?: string | null;
}

export interface CommentReactionCreateManyCommentInputEnvelope {
  data?: CommentReactionCreateManyCommentInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface CommentReactionCreateManyCreatedByInput {
  id: string;
  reactionType?: ReactionType | null;
  commentId: string;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  modifiedBy?: string | null;
}

export interface CommentReactionCreateManyCreatedByInputEnvelope {
  data?: CommentReactionCreateManyCreatedByInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface CommentReactionCreateNestedManyWithoutCommentInput {
  create?: CommentReactionCreateWithoutCommentInput[] | null;
  connectOrCreate?: CommentReactionCreateOrConnectWithoutCommentInput[] | null;
  createMany?: CommentReactionCreateManyCommentInputEnvelope | null;
  connect?: CommentReactionWhereUniqueInput[] | null;
}

export interface CommentReactionCreateNestedManyWithoutCreatedByInput {
  create?: CommentReactionCreateWithoutCreatedByInput[] | null;
  connectOrCreate?: CommentReactionCreateOrConnectWithoutCreatedByInput[] | null;
  createMany?: CommentReactionCreateManyCreatedByInputEnvelope | null;
  connect?: CommentReactionWhereUniqueInput[] | null;
}

export interface CommentReactionCreateOrConnectWithoutCommentInput {
  where: CommentReactionWhereUniqueInput;
  create: CommentReactionCreateWithoutCommentInput;
}

export interface CommentReactionCreateOrConnectWithoutCreatedByInput {
  where: CommentReactionWhereUniqueInput;
  create: CommentReactionCreateWithoutCreatedByInput;
}

export interface CommentReactionCreateWithoutCommentInput {
  id: string;
  reactionType?: ReactionType | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  modifiedBy?: string | null;
  createdBy: UserCreateNestedOneWithoutCommentReactionsInput;
}

export interface CommentReactionCreateWithoutCreatedByInput {
  id: string;
  reactionType?: ReactionType | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  modifiedBy?: string | null;
  comment?: CommentCreateNestedOneWithoutReactionsInput | null;
}

export interface CommentReactionListRelationFilter {
  every?: CommentReactionWhereInput | null;
  some?: CommentReactionWhereInput | null;
  none?: CommentReactionWhereInput | null;
}

export interface CommentReactionOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface CommentReactionScalarWhereInput {
  AND?: CommentReactionScalarWhereInput[] | null;
  OR?: CommentReactionScalarWhereInput[] | null;
  NOT?: CommentReactionScalarWhereInput[] | null;
  id?: StringFilter | null;
  reactionType?: EnumReactionTypeFilter | null;
  commentId?: StringFilter | null;
  userId?: StringFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  modifiedBy?: StringNullableFilter | null;
}

export interface CommentReactionUpdateInput {
  id?: StringFieldUpdateOperationsInput | null;
  reactionType?: EnumReactionTypeFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  modifiedBy?: NullableStringFieldUpdateOperationsInput | null;
  comment?: CommentUpdateOneWithoutReactionsInput | null;
  createdBy?: UserUpdateOneRequiredWithoutCommentReactionsInput | null;
}

export interface CommentReactionUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  reactionType?: EnumReactionTypeFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  modifiedBy?: NullableStringFieldUpdateOperationsInput | null;
}

export interface CommentReactionUpdateManyWithWhereWithoutCommentInput {
  where: CommentReactionScalarWhereInput;
  data: CommentReactionUpdateManyMutationInput;
}

export interface CommentReactionUpdateManyWithWhereWithoutCreatedByInput {
  where: CommentReactionScalarWhereInput;
  data: CommentReactionUpdateManyMutationInput;
}

export interface CommentReactionUpdateManyWithoutCommentInput {
  create?: CommentReactionCreateWithoutCommentInput[] | null;
  connectOrCreate?: CommentReactionCreateOrConnectWithoutCommentInput[] | null;
  upsert?: CommentReactionUpsertWithWhereUniqueWithoutCommentInput[] | null;
  createMany?: CommentReactionCreateManyCommentInputEnvelope | null;
  connect?: CommentReactionWhereUniqueInput[] | null;
  set?: CommentReactionWhereUniqueInput[] | null;
  disconnect?: CommentReactionWhereUniqueInput[] | null;
  delete?: CommentReactionWhereUniqueInput[] | null;
  update?: CommentReactionUpdateWithWhereUniqueWithoutCommentInput[] | null;
  updateMany?: CommentReactionUpdateManyWithWhereWithoutCommentInput[] | null;
  deleteMany?: CommentReactionScalarWhereInput[] | null;
}

export interface CommentReactionUpdateManyWithoutCreatedByInput {
  create?: CommentReactionCreateWithoutCreatedByInput[] | null;
  connectOrCreate?: CommentReactionCreateOrConnectWithoutCreatedByInput[] | null;
  upsert?: CommentReactionUpsertWithWhereUniqueWithoutCreatedByInput[] | null;
  createMany?: CommentReactionCreateManyCreatedByInputEnvelope | null;
  connect?: CommentReactionWhereUniqueInput[] | null;
  set?: CommentReactionWhereUniqueInput[] | null;
  disconnect?: CommentReactionWhereUniqueInput[] | null;
  delete?: CommentReactionWhereUniqueInput[] | null;
  update?: CommentReactionUpdateWithWhereUniqueWithoutCreatedByInput[] | null;
  updateMany?: CommentReactionUpdateManyWithWhereWithoutCreatedByInput[] | null;
  deleteMany?: CommentReactionScalarWhereInput[] | null;
}

export interface CommentReactionUpdateWithWhereUniqueWithoutCommentInput {
  where: CommentReactionWhereUniqueInput;
  data: CommentReactionUpdateWithoutCommentInput;
}

export interface CommentReactionUpdateWithWhereUniqueWithoutCreatedByInput {
  where: CommentReactionWhereUniqueInput;
  data: CommentReactionUpdateWithoutCreatedByInput;
}

export interface CommentReactionUpdateWithoutCommentInput {
  id?: StringFieldUpdateOperationsInput | null;
  reactionType?: EnumReactionTypeFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  modifiedBy?: NullableStringFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneRequiredWithoutCommentReactionsInput | null;
}

export interface CommentReactionUpdateWithoutCreatedByInput {
  id?: StringFieldUpdateOperationsInput | null;
  reactionType?: EnumReactionTypeFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  modifiedBy?: NullableStringFieldUpdateOperationsInput | null;
  comment?: CommentUpdateOneWithoutReactionsInput | null;
}

export interface CommentReactionUpsertWithWhereUniqueWithoutCommentInput {
  where: CommentReactionWhereUniqueInput;
  update: CommentReactionUpdateWithoutCommentInput;
  create: CommentReactionCreateWithoutCommentInput;
}

export interface CommentReactionUpsertWithWhereUniqueWithoutCreatedByInput {
  where: CommentReactionWhereUniqueInput;
  update: CommentReactionUpdateWithoutCreatedByInput;
  create: CommentReactionCreateWithoutCreatedByInput;
}

export interface CommentReactionUserIdCommentIdCompoundUniqueInput {
  userId: string;
  commentId: string;
}

export interface CommentReactionWhereInput {
  AND?: CommentReactionWhereInput[] | null;
  OR?: CommentReactionWhereInput[] | null;
  NOT?: CommentReactionWhereInput[] | null;
  id?: StringFilter | null;
  reactionType?: EnumReactionTypeFilter | null;
  commentId?: StringFilter | null;
  comment?: CommentWhereInput | null;
  userId?: StringFilter | null;
  createdBy?: UserWhereInput | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  modifiedBy?: StringNullableFilter | null;
}

export interface CommentReactionWhereUniqueInput {
  id?: string | null;
  userId_commentId?: CommentReactionUserIdCommentIdCompoundUniqueInput | null;
}

export interface CommentScalarWhereInput {
  AND?: CommentScalarWhereInput[] | null;
  OR?: CommentScalarWhereInput[] | null;
  NOT?: CommentScalarWhereInput[] | null;
  id?: StringFilter | null;
  content?: StringFilter | null;
  isPublished?: BoolFilter | null;
  postId?: StringFilter | null;
  nickName?: StringNullableFilter | null;
  userId?: StringFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  modifiedBy?: StringNullableFilter | null;
}

export interface CommentUpdateInput {
  id?: StringFieldUpdateOperationsInput | null;
  content?: StringFieldUpdateOperationsInput | null;
  isPublished?: BoolFieldUpdateOperationsInput | null;
  nickName?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  modifiedBy?: NullableStringFieldUpdateOperationsInput | null;
  post?: PostUpdateOneRequiredWithoutCommentsInput | null;
  reactions?: CommentReactionUpdateManyWithoutCommentInput | null;
  createdBy?: UserUpdateOneRequiredWithoutCommentsInput | null;
}

export interface CommentUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  content?: StringFieldUpdateOperationsInput | null;
  isPublished?: BoolFieldUpdateOperationsInput | null;
  nickName?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  modifiedBy?: NullableStringFieldUpdateOperationsInput | null;
}

export interface CommentUpdateManyWithWhereWithoutCreatedByInput {
  where: CommentScalarWhereInput;
  data: CommentUpdateManyMutationInput;
}

export interface CommentUpdateManyWithWhereWithoutPostInput {
  where: CommentScalarWhereInput;
  data: CommentUpdateManyMutationInput;
}

export interface CommentUpdateManyWithoutCreatedByInput {
  create?: CommentCreateWithoutCreatedByInput[] | null;
  connectOrCreate?: CommentCreateOrConnectWithoutCreatedByInput[] | null;
  upsert?: CommentUpsertWithWhereUniqueWithoutCreatedByInput[] | null;
  createMany?: CommentCreateManyCreatedByInputEnvelope | null;
  connect?: CommentWhereUniqueInput[] | null;
  set?: CommentWhereUniqueInput[] | null;
  disconnect?: CommentWhereUniqueInput[] | null;
  delete?: CommentWhereUniqueInput[] | null;
  update?: CommentUpdateWithWhereUniqueWithoutCreatedByInput[] | null;
  updateMany?: CommentUpdateManyWithWhereWithoutCreatedByInput[] | null;
  deleteMany?: CommentScalarWhereInput[] | null;
}

export interface CommentUpdateManyWithoutPostInput {
  create?: CommentCreateWithoutPostInput[] | null;
  connectOrCreate?: CommentCreateOrConnectWithoutPostInput[] | null;
  upsert?: CommentUpsertWithWhereUniqueWithoutPostInput[] | null;
  createMany?: CommentCreateManyPostInputEnvelope | null;
  connect?: CommentWhereUniqueInput[] | null;
  set?: CommentWhereUniqueInput[] | null;
  disconnect?: CommentWhereUniqueInput[] | null;
  delete?: CommentWhereUniqueInput[] | null;
  update?: CommentUpdateWithWhereUniqueWithoutPostInput[] | null;
  updateMany?: CommentUpdateManyWithWhereWithoutPostInput[] | null;
  deleteMany?: CommentScalarWhereInput[] | null;
}

export interface CommentUpdateOneWithoutReactionsInput {
  create?: CommentCreateWithoutReactionsInput | null;
  connectOrCreate?: CommentCreateOrConnectWithoutReactionsInput | null;
  upsert?: CommentUpsertWithoutReactionsInput | null;
  connect?: CommentWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: CommentUpdateWithoutReactionsInput | null;
}

export interface CommentUpdateWithWhereUniqueWithoutCreatedByInput {
  where: CommentWhereUniqueInput;
  data: CommentUpdateWithoutCreatedByInput;
}

export interface CommentUpdateWithWhereUniqueWithoutPostInput {
  where: CommentWhereUniqueInput;
  data: CommentUpdateWithoutPostInput;
}

export interface CommentUpdateWithoutCreatedByInput {
  id?: StringFieldUpdateOperationsInput | null;
  content?: StringFieldUpdateOperationsInput | null;
  isPublished?: BoolFieldUpdateOperationsInput | null;
  nickName?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  modifiedBy?: NullableStringFieldUpdateOperationsInput | null;
  post?: PostUpdateOneRequiredWithoutCommentsInput | null;
  reactions?: CommentReactionUpdateManyWithoutCommentInput | null;
}

export interface CommentUpdateWithoutPostInput {
  id?: StringFieldUpdateOperationsInput | null;
  content?: StringFieldUpdateOperationsInput | null;
  isPublished?: BoolFieldUpdateOperationsInput | null;
  nickName?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  modifiedBy?: NullableStringFieldUpdateOperationsInput | null;
  reactions?: CommentReactionUpdateManyWithoutCommentInput | null;
  createdBy?: UserUpdateOneRequiredWithoutCommentsInput | null;
}

export interface CommentUpdateWithoutReactionsInput {
  id?: StringFieldUpdateOperationsInput | null;
  content?: StringFieldUpdateOperationsInput | null;
  isPublished?: BoolFieldUpdateOperationsInput | null;
  nickName?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  modifiedBy?: NullableStringFieldUpdateOperationsInput | null;
  post?: PostUpdateOneRequiredWithoutCommentsInput | null;
  createdBy?: UserUpdateOneRequiredWithoutCommentsInput | null;
}

export interface CommentUpsertWithWhereUniqueWithoutCreatedByInput {
  where: CommentWhereUniqueInput;
  update: CommentUpdateWithoutCreatedByInput;
  create: CommentCreateWithoutCreatedByInput;
}

export interface CommentUpsertWithWhereUniqueWithoutPostInput {
  where: CommentWhereUniqueInput;
  update: CommentUpdateWithoutPostInput;
  create: CommentCreateWithoutPostInput;
}

export interface CommentUpsertWithoutReactionsInput {
  update: CommentUpdateWithoutReactionsInput;
  create: CommentCreateWithoutReactionsInput;
}

export interface CommentWhereInput {
  AND?: CommentWhereInput[] | null;
  OR?: CommentWhereInput[] | null;
  NOT?: CommentWhereInput[] | null;
  id?: StringFilter | null;
  content?: StringFilter | null;
  isPublished?: BoolFilter | null;
  postId?: StringFilter | null;
  post?: PostWhereInput | null;
  reactions?: CommentReactionListRelationFilter | null;
  nickName?: StringNullableFilter | null;
  userId?: StringFilter | null;
  createdBy?: UserWhereInput | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  modifiedBy?: StringNullableFilter | null;
}

export interface CommentWhereUniqueInput {
  id?: string | null;
}

export interface DateTimeFieldUpdateOperationsInput {
  set?: SPEAKUPDateTime | null;
}

export interface DateTimeFilter {
  equals?: SPEAKUPDateTime | null;
  in?: SPEAKUPDateTime[] | null;
  notIn?: SPEAKUPDateTime[] | null;
  lt?: SPEAKUPDateTime | null;
  lte?: SPEAKUPDateTime | null;
  gt?: SPEAKUPDateTime | null;
  gte?: SPEAKUPDateTime | null;
  not?: NestedDateTimeFilter | null;
}

export interface EnumAttendeeRoleFieldUpdateOperationsInput {
  set?: AttendeeRole | null;
}

export interface EnumAttendeeRoleFilter {
  equals?: AttendeeRole | null;
  in?: AttendeeRole[] | null;
  notIn?: AttendeeRole[] | null;
  not?: NestedEnumAttendeeRoleFilter | null;
}

export interface EnumLanguageFieldUpdateOperationsInput {
  set?: Language | null;
}

export interface EnumLanguageFilter {
  equals?: Language | null;
  in?: Language[] | null;
  notIn?: Language[] | null;
  not?: NestedEnumLanguageFilter | null;
}

export interface EnumReactionTypeFieldUpdateOperationsInput {
  set?: ReactionType | null;
}

export interface EnumReactionTypeFilter {
  equals?: ReactionType | null;
  in?: ReactionType[] | null;
  notIn?: ReactionType[] | null;
  not?: NestedEnumReactionTypeFilter | null;
}

export interface EnumThemeFieldUpdateOperationsInput {
  set?: Theme | null;
}

export interface EnumThemeFilter {
  equals?: Theme | null;
  in?: Theme[] | null;
  notIn?: Theme[] | null;
  not?: NestedEnumThemeFilter | null;
}

export interface IntFieldUpdateOperationsInput {
  set?: number | null;
  increment?: number | null;
  decrement?: number | null;
  multiply?: number | null;
  divide?: number | null;
}

export interface IntFilter {
  equals?: number | null;
  in?: number[] | null;
  notIn?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: NestedIntFilter | null;
}

export interface JsonNullableFilter {
  equals?: JsonNullValueFilter | null;
  not?: JsonNullValueFilter | null;
}

export interface NestedBoolFilter {
  equals?: boolean | null;
  not?: NestedBoolFilter | null;
}

export interface NestedDateTimeFilter {
  equals?: SPEAKUPDateTime | null;
  in?: SPEAKUPDateTime[] | null;
  notIn?: SPEAKUPDateTime[] | null;
  lt?: SPEAKUPDateTime | null;
  lte?: SPEAKUPDateTime | null;
  gt?: SPEAKUPDateTime | null;
  gte?: SPEAKUPDateTime | null;
  not?: NestedDateTimeFilter | null;
}

export interface NestedEnumAttendeeRoleFilter {
  equals?: AttendeeRole | null;
  in?: AttendeeRole[] | null;
  notIn?: AttendeeRole[] | null;
  not?: NestedEnumAttendeeRoleFilter | null;
}

export interface NestedEnumLanguageFilter {
  equals?: Language | null;
  in?: Language[] | null;
  notIn?: Language[] | null;
  not?: NestedEnumLanguageFilter | null;
}

export interface NestedEnumReactionTypeFilter {
  equals?: ReactionType | null;
  in?: ReactionType[] | null;
  notIn?: ReactionType[] | null;
  not?: NestedEnumReactionTypeFilter | null;
}

export interface NestedEnumThemeFilter {
  equals?: Theme | null;
  in?: Theme[] | null;
  notIn?: Theme[] | null;
  not?: NestedEnumThemeFilter | null;
}

export interface NestedIntFilter {
  equals?: number | null;
  in?: number[] | null;
  notIn?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: NestedIntFilter | null;
}

export interface NestedStringFilter {
  equals?: string | null;
  in?: string[] | null;
  notIn?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  not?: NestedStringFilter | null;
}

export interface NestedStringNullableFilter {
  equals?: string | null;
  in?: string[] | null;
  notIn?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  not?: NestedStringNullableFilter | null;
}

export interface NullableStringFieldUpdateOperationsInput {
  set?: string | null;
}

export interface PollCreateInput {
  id?: string | null;
  optionsLength?: number | null;
  content: string;
  isMultipleResponses?: boolean | null;
  isSolutions?: boolean | null;
  isImmediateResults?: boolean | null;
  isOpened?: boolean | null;
  isResults?: boolean | null;
  isImmediateResultsBroadcast?: boolean | null;
  extendedResultData?: NullableJsonNullValueInput | null;
  nickName?: string | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  modifiedBy?: string | null;
  room: RoomCreateNestedOneWithoutPollsInput;
  options?: PollOptionCreateNestedManyWithoutPollInput | null;
  createdBy: UserCreateNestedOneWithoutPollsInput;
}

export interface PollCreateManyCreatedByInput {
  id?: string | null;
  optionsLength?: number | null;
  content: string;
  isMultipleResponses?: boolean | null;
  isSolutions?: boolean | null;
  isImmediateResults?: boolean | null;
  isOpened?: boolean | null;
  isResults?: boolean | null;
  isImmediateResultsBroadcast?: boolean | null;
  roomId: string;
  extendedResultData?: NullableJsonNullValueInput | null;
  nickName?: string | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  modifiedBy?: string | null;
}

export interface PollCreateManyCreatedByInputEnvelope {
  data?: PollCreateManyCreatedByInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface PollCreateManyRoomInput {
  id?: string | null;
  optionsLength?: number | null;
  content: string;
  isMultipleResponses?: boolean | null;
  isSolutions?: boolean | null;
  isImmediateResults?: boolean | null;
  isOpened?: boolean | null;
  isResults?: boolean | null;
  isImmediateResultsBroadcast?: boolean | null;
  extendedResultData?: NullableJsonNullValueInput | null;
  nickName?: string | null;
  userId: string;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  modifiedBy?: string | null;
}

export interface PollCreateManyRoomInputEnvelope {
  data?: PollCreateManyRoomInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface PollCreateNestedManyWithoutCreatedByInput {
  create?: PollCreateWithoutCreatedByInput[] | null;
  connectOrCreate?: PollCreateOrConnectWithoutCreatedByInput[] | null;
  createMany?: PollCreateManyCreatedByInputEnvelope | null;
  connect?: PollWhereUniqueInput[] | null;
}

export interface PollCreateNestedManyWithoutRoomInput {
  create?: PollCreateWithoutRoomInput[] | null;
  connectOrCreate?: PollCreateOrConnectWithoutRoomInput[] | null;
  createMany?: PollCreateManyRoomInputEnvelope | null;
  connect?: PollWhereUniqueInput[] | null;
}

export interface PollCreateNestedOneWithoutOptionsInput {
  create?: PollCreateWithoutOptionsInput | null;
  connectOrCreate?: PollCreateOrConnectWithoutOptionsInput | null;
  connect?: PollWhereUniqueInput | null;
}

export interface PollCreateOrConnectWithoutCreatedByInput {
  where: PollWhereUniqueInput;
  create: PollCreateWithoutCreatedByInput;
}

export interface PollCreateOrConnectWithoutOptionsInput {
  where: PollWhereUniqueInput;
  create: PollCreateWithoutOptionsInput;
}

export interface PollCreateOrConnectWithoutRoomInput {
  where: PollWhereUniqueInput;
  create: PollCreateWithoutRoomInput;
}

export interface PollCreateWithoutCreatedByInput {
  id?: string | null;
  optionsLength?: number | null;
  content: string;
  isMultipleResponses?: boolean | null;
  isSolutions?: boolean | null;
  isImmediateResults?: boolean | null;
  isOpened?: boolean | null;
  isResults?: boolean | null;
  isImmediateResultsBroadcast?: boolean | null;
  extendedResultData?: NullableJsonNullValueInput | null;
  nickName?: string | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  modifiedBy?: string | null;
  room: RoomCreateNestedOneWithoutPollsInput;
  options?: PollOptionCreateNestedManyWithoutPollInput | null;
}

export interface PollCreateWithoutOptionsInput {
  id?: string | null;
  optionsLength?: number | null;
  content: string;
  isMultipleResponses?: boolean | null;
  isSolutions?: boolean | null;
  isImmediateResults?: boolean | null;
  isOpened?: boolean | null;
  isResults?: boolean | null;
  isImmediateResultsBroadcast?: boolean | null;
  extendedResultData?: NullableJsonNullValueInput | null;
  nickName?: string | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  modifiedBy?: string | null;
  room: RoomCreateNestedOneWithoutPollsInput;
  createdBy: UserCreateNestedOneWithoutPollsInput;
}

export interface PollCreateWithoutRoomInput {
  id?: string | null;
  optionsLength?: number | null;
  content: string;
  isMultipleResponses?: boolean | null;
  isSolutions?: boolean | null;
  isImmediateResults?: boolean | null;
  isOpened?: boolean | null;
  isResults?: boolean | null;
  isImmediateResultsBroadcast?: boolean | null;
  extendedResultData?: NullableJsonNullValueInput | null;
  nickName?: string | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  modifiedBy?: string | null;
  options?: PollOptionCreateNestedManyWithoutPollInput | null;
  createdBy: UserCreateNestedOneWithoutPollsInput;
}

export interface PollListRelationFilter {
  every?: PollWhereInput | null;
  some?: PollWhereInput | null;
  none?: PollWhereInput | null;
}

export interface PollOptionCreateManyPollInput {
  id?: string | null;
  label: string;
  isChecked?: boolean | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  modifiedBy?: string | null;
}

export interface PollOptionCreateManyPollInputEnvelope {
  data?: PollOptionCreateManyPollInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface PollOptionCreateNestedManyWithoutPollInput {
  create?: PollOptionCreateWithoutPollInput[] | null;
  connectOrCreate?: PollOptionCreateOrConnectWithoutPollInput[] | null;
  createMany?: PollOptionCreateManyPollInputEnvelope | null;
  connect?: PollOptionWhereUniqueInput[] | null;
}

export interface PollOptionCreateNestedOneWithoutResultsInput {
  create?: PollOptionCreateWithoutResultsInput | null;
  connectOrCreate?: PollOptionCreateOrConnectWithoutResultsInput | null;
  connect?: PollOptionWhereUniqueInput | null;
}

export interface PollOptionCreateOrConnectWithoutPollInput {
  where: PollOptionWhereUniqueInput;
  create: PollOptionCreateWithoutPollInput;
}

export interface PollOptionCreateOrConnectWithoutResultsInput {
  where: PollOptionWhereUniqueInput;
  create: PollOptionCreateWithoutResultsInput;
}

export interface PollOptionCreateWithoutPollInput {
  id?: string | null;
  label: string;
  isChecked?: boolean | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  modifiedBy?: string | null;
  results?: PollOptionResultCreateNestedManyWithoutPollOptionInput | null;
}

export interface PollOptionCreateWithoutResultsInput {
  id?: string | null;
  label: string;
  isChecked?: boolean | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  modifiedBy?: string | null;
  poll: PollCreateNestedOneWithoutOptionsInput;
}

export interface PollOptionListRelationFilter {
  every?: PollOptionWhereInput | null;
  some?: PollOptionWhereInput | null;
  none?: PollOptionWhereInput | null;
}

export interface PollOptionResultCreateManyCreatedByInput {
  id: string;
  pollOptionId: string;
  isChecked?: boolean | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
}

export interface PollOptionResultCreateManyCreatedByInputEnvelope {
  data?: PollOptionResultCreateManyCreatedByInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface PollOptionResultCreateManyPollOptionInput {
  id: string;
  userId: string;
  isChecked?: boolean | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
}

export interface PollOptionResultCreateManyPollOptionInputEnvelope {
  data?: PollOptionResultCreateManyPollOptionInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface PollOptionResultCreateNestedManyWithoutCreatedByInput {
  create?: PollOptionResultCreateWithoutCreatedByInput[] | null;
  connectOrCreate?: PollOptionResultCreateOrConnectWithoutCreatedByInput[] | null;
  createMany?: PollOptionResultCreateManyCreatedByInputEnvelope | null;
  connect?: PollOptionResultWhereUniqueInput[] | null;
}

export interface PollOptionResultCreateNestedManyWithoutPollOptionInput {
  create?: PollOptionResultCreateWithoutPollOptionInput[] | null;
  connectOrCreate?: PollOptionResultCreateOrConnectWithoutPollOptionInput[] | null;
  createMany?: PollOptionResultCreateManyPollOptionInputEnvelope | null;
  connect?: PollOptionResultWhereUniqueInput[] | null;
}

export interface PollOptionResultCreateOrConnectWithoutCreatedByInput {
  where: PollOptionResultWhereUniqueInput;
  create: PollOptionResultCreateWithoutCreatedByInput;
}

export interface PollOptionResultCreateOrConnectWithoutPollOptionInput {
  where: PollOptionResultWhereUniqueInput;
  create: PollOptionResultCreateWithoutPollOptionInput;
}

export interface PollOptionResultCreateWithoutCreatedByInput {
  id: string;
  isChecked?: boolean | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  pollOption: PollOptionCreateNestedOneWithoutResultsInput;
}

export interface PollOptionResultCreateWithoutPollOptionInput {
  id: string;
  isChecked?: boolean | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  createdBy: UserCreateNestedOneWithoutPollResultsInput;
}

export interface PollOptionResultListRelationFilter {
  every?: PollOptionResultWhereInput | null;
  some?: PollOptionResultWhereInput | null;
  none?: PollOptionResultWhereInput | null;
}

export interface PollOptionResultOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface PollOptionResultScalarWhereInput {
  AND?: PollOptionResultScalarWhereInput[] | null;
  OR?: PollOptionResultScalarWhereInput[] | null;
  NOT?: PollOptionResultScalarWhereInput[] | null;
  id?: StringFilter | null;
  pollOptionId?: StringFilter | null;
  userId?: StringFilter | null;
  isChecked?: BoolFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface PollOptionResultUpdateInput {
  id?: StringFieldUpdateOperationsInput | null;
  isChecked?: BoolFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  pollOption?: PollOptionUpdateOneRequiredWithoutResultsInput | null;
  createdBy?: UserUpdateOneRequiredWithoutPollResultsInput | null;
}

export interface PollOptionResultUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  isChecked?: BoolFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
}

export interface PollOptionResultUpdateManyWithWhereWithoutCreatedByInput {
  where: PollOptionResultScalarWhereInput;
  data: PollOptionResultUpdateManyMutationInput;
}

export interface PollOptionResultUpdateManyWithWhereWithoutPollOptionInput {
  where: PollOptionResultScalarWhereInput;
  data: PollOptionResultUpdateManyMutationInput;
}

export interface PollOptionResultUpdateManyWithoutCreatedByInput {
  create?: PollOptionResultCreateWithoutCreatedByInput[] | null;
  connectOrCreate?: PollOptionResultCreateOrConnectWithoutCreatedByInput[] | null;
  upsert?: PollOptionResultUpsertWithWhereUniqueWithoutCreatedByInput[] | null;
  createMany?: PollOptionResultCreateManyCreatedByInputEnvelope | null;
  connect?: PollOptionResultWhereUniqueInput[] | null;
  set?: PollOptionResultWhereUniqueInput[] | null;
  disconnect?: PollOptionResultWhereUniqueInput[] | null;
  delete?: PollOptionResultWhereUniqueInput[] | null;
  update?: PollOptionResultUpdateWithWhereUniqueWithoutCreatedByInput[] | null;
  updateMany?: PollOptionResultUpdateManyWithWhereWithoutCreatedByInput[] | null;
  deleteMany?: PollOptionResultScalarWhereInput[] | null;
}

export interface PollOptionResultUpdateManyWithoutPollOptionInput {
  create?: PollOptionResultCreateWithoutPollOptionInput[] | null;
  connectOrCreate?: PollOptionResultCreateOrConnectWithoutPollOptionInput[] | null;
  upsert?: PollOptionResultUpsertWithWhereUniqueWithoutPollOptionInput[] | null;
  createMany?: PollOptionResultCreateManyPollOptionInputEnvelope | null;
  connect?: PollOptionResultWhereUniqueInput[] | null;
  set?: PollOptionResultWhereUniqueInput[] | null;
  disconnect?: PollOptionResultWhereUniqueInput[] | null;
  delete?: PollOptionResultWhereUniqueInput[] | null;
  update?: PollOptionResultUpdateWithWhereUniqueWithoutPollOptionInput[] | null;
  updateMany?: PollOptionResultUpdateManyWithWhereWithoutPollOptionInput[] | null;
  deleteMany?: PollOptionResultScalarWhereInput[] | null;
}

export interface PollOptionResultUpdateWithWhereUniqueWithoutCreatedByInput {
  where: PollOptionResultWhereUniqueInput;
  data: PollOptionResultUpdateWithoutCreatedByInput;
}

export interface PollOptionResultUpdateWithWhereUniqueWithoutPollOptionInput {
  where: PollOptionResultWhereUniqueInput;
  data: PollOptionResultUpdateWithoutPollOptionInput;
}

export interface PollOptionResultUpdateWithoutCreatedByInput {
  id?: StringFieldUpdateOperationsInput | null;
  isChecked?: BoolFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  pollOption?: PollOptionUpdateOneRequiredWithoutResultsInput | null;
}

export interface PollOptionResultUpdateWithoutPollOptionInput {
  id?: StringFieldUpdateOperationsInput | null;
  isChecked?: BoolFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneRequiredWithoutPollResultsInput | null;
}

export interface PollOptionResultUpsertWithWhereUniqueWithoutCreatedByInput {
  where: PollOptionResultWhereUniqueInput;
  update: PollOptionResultUpdateWithoutCreatedByInput;
  create: PollOptionResultCreateWithoutCreatedByInput;
}

export interface PollOptionResultUpsertWithWhereUniqueWithoutPollOptionInput {
  where: PollOptionResultWhereUniqueInput;
  update: PollOptionResultUpdateWithoutPollOptionInput;
  create: PollOptionResultCreateWithoutPollOptionInput;
}

export interface PollOptionResultUserIdPollOptionIdCompoundUniqueInput {
  userId: string;
  pollOptionId: string;
}

export interface PollOptionResultWhereInput {
  AND?: PollOptionResultWhereInput[] | null;
  OR?: PollOptionResultWhereInput[] | null;
  NOT?: PollOptionResultWhereInput[] | null;
  id?: StringFilter | null;
  pollOptionId?: StringFilter | null;
  pollOption?: PollOptionWhereInput | null;
  userId?: StringFilter | null;
  createdBy?: UserWhereInput | null;
  isChecked?: BoolFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface PollOptionResultWhereUniqueInput {
  id?: string | null;
  userId_pollOptionId?: PollOptionResultUserIdPollOptionIdCompoundUniqueInput | null;
}

export interface PollOptionScalarWhereInput {
  AND?: PollOptionScalarWhereInput[] | null;
  OR?: PollOptionScalarWhereInput[] | null;
  NOT?: PollOptionScalarWhereInput[] | null;
  id?: StringFilter | null;
  label?: StringFilter | null;
  pollId?: StringFilter | null;
  isChecked?: BoolFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  modifiedBy?: StringNullableFilter | null;
}

export interface PollOptionUpdateInput {
  id?: StringFieldUpdateOperationsInput | null;
  label?: StringFieldUpdateOperationsInput | null;
  isChecked?: BoolFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  modifiedBy?: NullableStringFieldUpdateOperationsInput | null;
  poll?: PollUpdateOneRequiredWithoutOptionsInput | null;
  results?: PollOptionResultUpdateManyWithoutPollOptionInput | null;
}

export interface PollOptionUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  label?: StringFieldUpdateOperationsInput | null;
  isChecked?: BoolFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  modifiedBy?: NullableStringFieldUpdateOperationsInput | null;
}

export interface PollOptionUpdateManyWithWhereWithoutPollInput {
  where: PollOptionScalarWhereInput;
  data: PollOptionUpdateManyMutationInput;
}

export interface PollOptionUpdateManyWithoutPollInput {
  create?: PollOptionCreateWithoutPollInput[] | null;
  connectOrCreate?: PollOptionCreateOrConnectWithoutPollInput[] | null;
  upsert?: PollOptionUpsertWithWhereUniqueWithoutPollInput[] | null;
  createMany?: PollOptionCreateManyPollInputEnvelope | null;
  connect?: PollOptionWhereUniqueInput[] | null;
  set?: PollOptionWhereUniqueInput[] | null;
  disconnect?: PollOptionWhereUniqueInput[] | null;
  delete?: PollOptionWhereUniqueInput[] | null;
  update?: PollOptionUpdateWithWhereUniqueWithoutPollInput[] | null;
  updateMany?: PollOptionUpdateManyWithWhereWithoutPollInput[] | null;
  deleteMany?: PollOptionScalarWhereInput[] | null;
}

export interface PollOptionUpdateOneRequiredWithoutResultsInput {
  create?: PollOptionCreateWithoutResultsInput | null;
  connectOrCreate?: PollOptionCreateOrConnectWithoutResultsInput | null;
  upsert?: PollOptionUpsertWithoutResultsInput | null;
  connect?: PollOptionWhereUniqueInput | null;
  update?: PollOptionUpdateWithoutResultsInput | null;
}

export interface PollOptionUpdateWithWhereUniqueWithoutPollInput {
  where: PollOptionWhereUniqueInput;
  data: PollOptionUpdateWithoutPollInput;
}

export interface PollOptionUpdateWithoutPollInput {
  id?: StringFieldUpdateOperationsInput | null;
  label?: StringFieldUpdateOperationsInput | null;
  isChecked?: BoolFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  modifiedBy?: NullableStringFieldUpdateOperationsInput | null;
  results?: PollOptionResultUpdateManyWithoutPollOptionInput | null;
}

export interface PollOptionUpdateWithoutResultsInput {
  id?: StringFieldUpdateOperationsInput | null;
  label?: StringFieldUpdateOperationsInput | null;
  isChecked?: BoolFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  modifiedBy?: NullableStringFieldUpdateOperationsInput | null;
  poll?: PollUpdateOneRequiredWithoutOptionsInput | null;
}

export interface PollOptionUpsertWithWhereUniqueWithoutPollInput {
  where: PollOptionWhereUniqueInput;
  update: PollOptionUpdateWithoutPollInput;
  create: PollOptionCreateWithoutPollInput;
}

export interface PollOptionUpsertWithoutResultsInput {
  update: PollOptionUpdateWithoutResultsInput;
  create: PollOptionCreateWithoutResultsInput;
}

export interface PollOptionWhereInput {
  AND?: PollOptionWhereInput[] | null;
  OR?: PollOptionWhereInput[] | null;
  NOT?: PollOptionWhereInput[] | null;
  id?: StringFilter | null;
  label?: StringFilter | null;
  pollId?: StringFilter | null;
  poll?: PollWhereInput | null;
  isChecked?: BoolFilter | null;
  results?: PollOptionResultListRelationFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  modifiedBy?: StringNullableFilter | null;
}

export interface PollOptionWhereUniqueInput {
  id?: string | null;
}

export interface PollOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface PollScalarWhereInput {
  AND?: PollScalarWhereInput[] | null;
  OR?: PollScalarWhereInput[] | null;
  NOT?: PollScalarWhereInput[] | null;
  id?: StringFilter | null;
  optionsLength?: IntFilter | null;
  content?: StringFilter | null;
  isMultipleResponses?: BoolFilter | null;
  isSolutions?: BoolFilter | null;
  isImmediateResults?: BoolFilter | null;
  isOpened?: BoolFilter | null;
  isResults?: BoolFilter | null;
  isImmediateResultsBroadcast?: BoolFilter | null;
  roomId?: StringFilter | null;
  extendedResultData?: JsonNullableFilter | null;
  nickName?: StringNullableFilter | null;
  userId?: StringFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  modifiedBy?: StringNullableFilter | null;
}

export interface PollUpdateInput {
  id?: StringFieldUpdateOperationsInput | null;
  optionsLength?: IntFieldUpdateOperationsInput | null;
  content?: StringFieldUpdateOperationsInput | null;
  isMultipleResponses?: BoolFieldUpdateOperationsInput | null;
  isSolutions?: BoolFieldUpdateOperationsInput | null;
  isImmediateResults?: BoolFieldUpdateOperationsInput | null;
  isOpened?: BoolFieldUpdateOperationsInput | null;
  isResults?: BoolFieldUpdateOperationsInput | null;
  isImmediateResultsBroadcast?: BoolFieldUpdateOperationsInput | null;
  extendedResultData?: NullableJsonNullValueInput | null;
  nickName?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  modifiedBy?: NullableStringFieldUpdateOperationsInput | null;
  room?: RoomUpdateOneRequiredWithoutPollsInput | null;
  options?: PollOptionUpdateManyWithoutPollInput | null;
  createdBy?: UserUpdateOneRequiredWithoutPollsInput | null;
}

export interface PollUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  optionsLength?: IntFieldUpdateOperationsInput | null;
  content?: StringFieldUpdateOperationsInput | null;
  isMultipleResponses?: BoolFieldUpdateOperationsInput | null;
  isSolutions?: BoolFieldUpdateOperationsInput | null;
  isImmediateResults?: BoolFieldUpdateOperationsInput | null;
  isOpened?: BoolFieldUpdateOperationsInput | null;
  isResults?: BoolFieldUpdateOperationsInput | null;
  isImmediateResultsBroadcast?: BoolFieldUpdateOperationsInput | null;
  extendedResultData?: NullableJsonNullValueInput | null;
  nickName?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  modifiedBy?: NullableStringFieldUpdateOperationsInput | null;
}

export interface PollUpdateManyWithWhereWithoutCreatedByInput {
  where: PollScalarWhereInput;
  data: PollUpdateManyMutationInput;
}

export interface PollUpdateManyWithWhereWithoutRoomInput {
  where: PollScalarWhereInput;
  data: PollUpdateManyMutationInput;
}

export interface PollUpdateManyWithoutCreatedByInput {
  create?: PollCreateWithoutCreatedByInput[] | null;
  connectOrCreate?: PollCreateOrConnectWithoutCreatedByInput[] | null;
  upsert?: PollUpsertWithWhereUniqueWithoutCreatedByInput[] | null;
  createMany?: PollCreateManyCreatedByInputEnvelope | null;
  connect?: PollWhereUniqueInput[] | null;
  set?: PollWhereUniqueInput[] | null;
  disconnect?: PollWhereUniqueInput[] | null;
  delete?: PollWhereUniqueInput[] | null;
  update?: PollUpdateWithWhereUniqueWithoutCreatedByInput[] | null;
  updateMany?: PollUpdateManyWithWhereWithoutCreatedByInput[] | null;
  deleteMany?: PollScalarWhereInput[] | null;
}

export interface PollUpdateManyWithoutRoomInput {
  create?: PollCreateWithoutRoomInput[] | null;
  connectOrCreate?: PollCreateOrConnectWithoutRoomInput[] | null;
  upsert?: PollUpsertWithWhereUniqueWithoutRoomInput[] | null;
  createMany?: PollCreateManyRoomInputEnvelope | null;
  connect?: PollWhereUniqueInput[] | null;
  set?: PollWhereUniqueInput[] | null;
  disconnect?: PollWhereUniqueInput[] | null;
  delete?: PollWhereUniqueInput[] | null;
  update?: PollUpdateWithWhereUniqueWithoutRoomInput[] | null;
  updateMany?: PollUpdateManyWithWhereWithoutRoomInput[] | null;
  deleteMany?: PollScalarWhereInput[] | null;
}

export interface PollUpdateOneRequiredWithoutOptionsInput {
  create?: PollCreateWithoutOptionsInput | null;
  connectOrCreate?: PollCreateOrConnectWithoutOptionsInput | null;
  upsert?: PollUpsertWithoutOptionsInput | null;
  connect?: PollWhereUniqueInput | null;
  update?: PollUpdateWithoutOptionsInput | null;
}

export interface PollUpdateWithWhereUniqueWithoutCreatedByInput {
  where: PollWhereUniqueInput;
  data: PollUpdateWithoutCreatedByInput;
}

export interface PollUpdateWithWhereUniqueWithoutRoomInput {
  where: PollWhereUniqueInput;
  data: PollUpdateWithoutRoomInput;
}

export interface PollUpdateWithoutCreatedByInput {
  id?: StringFieldUpdateOperationsInput | null;
  optionsLength?: IntFieldUpdateOperationsInput | null;
  content?: StringFieldUpdateOperationsInput | null;
  isMultipleResponses?: BoolFieldUpdateOperationsInput | null;
  isSolutions?: BoolFieldUpdateOperationsInput | null;
  isImmediateResults?: BoolFieldUpdateOperationsInput | null;
  isOpened?: BoolFieldUpdateOperationsInput | null;
  isResults?: BoolFieldUpdateOperationsInput | null;
  isImmediateResultsBroadcast?: BoolFieldUpdateOperationsInput | null;
  extendedResultData?: NullableJsonNullValueInput | null;
  nickName?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  modifiedBy?: NullableStringFieldUpdateOperationsInput | null;
  room?: RoomUpdateOneRequiredWithoutPollsInput | null;
  options?: PollOptionUpdateManyWithoutPollInput | null;
}

export interface PollUpdateWithoutOptionsInput {
  id?: StringFieldUpdateOperationsInput | null;
  optionsLength?: IntFieldUpdateOperationsInput | null;
  content?: StringFieldUpdateOperationsInput | null;
  isMultipleResponses?: BoolFieldUpdateOperationsInput | null;
  isSolutions?: BoolFieldUpdateOperationsInput | null;
  isImmediateResults?: BoolFieldUpdateOperationsInput | null;
  isOpened?: BoolFieldUpdateOperationsInput | null;
  isResults?: BoolFieldUpdateOperationsInput | null;
  isImmediateResultsBroadcast?: BoolFieldUpdateOperationsInput | null;
  extendedResultData?: NullableJsonNullValueInput | null;
  nickName?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  modifiedBy?: NullableStringFieldUpdateOperationsInput | null;
  room?: RoomUpdateOneRequiredWithoutPollsInput | null;
  createdBy?: UserUpdateOneRequiredWithoutPollsInput | null;
}

export interface PollUpdateWithoutRoomInput {
  id?: StringFieldUpdateOperationsInput | null;
  optionsLength?: IntFieldUpdateOperationsInput | null;
  content?: StringFieldUpdateOperationsInput | null;
  isMultipleResponses?: BoolFieldUpdateOperationsInput | null;
  isSolutions?: BoolFieldUpdateOperationsInput | null;
  isImmediateResults?: BoolFieldUpdateOperationsInput | null;
  isOpened?: BoolFieldUpdateOperationsInput | null;
  isResults?: BoolFieldUpdateOperationsInput | null;
  isImmediateResultsBroadcast?: BoolFieldUpdateOperationsInput | null;
  extendedResultData?: NullableJsonNullValueInput | null;
  nickName?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  modifiedBy?: NullableStringFieldUpdateOperationsInput | null;
  options?: PollOptionUpdateManyWithoutPollInput | null;
  createdBy?: UserUpdateOneRequiredWithoutPollsInput | null;
}

export interface PollUpsertWithWhereUniqueWithoutCreatedByInput {
  where: PollWhereUniqueInput;
  update: PollUpdateWithoutCreatedByInput;
  create: PollCreateWithoutCreatedByInput;
}

export interface PollUpsertWithWhereUniqueWithoutRoomInput {
  where: PollWhereUniqueInput;
  update: PollUpdateWithoutRoomInput;
  create: PollCreateWithoutRoomInput;
}

export interface PollUpsertWithoutOptionsInput {
  update: PollUpdateWithoutOptionsInput;
  create: PollCreateWithoutOptionsInput;
}

export interface PollWhereInput {
  AND?: PollWhereInput[] | null;
  OR?: PollWhereInput[] | null;
  NOT?: PollWhereInput[] | null;
  id?: StringFilter | null;
  optionsLength?: IntFilter | null;
  content?: StringFilter | null;
  isMultipleResponses?: BoolFilter | null;
  isSolutions?: BoolFilter | null;
  isImmediateResults?: BoolFilter | null;
  isOpened?: BoolFilter | null;
  isResults?: BoolFilter | null;
  isImmediateResultsBroadcast?: BoolFilter | null;
  roomId?: StringFilter | null;
  room?: RoomWhereInput | null;
  options?: PollOptionListRelationFilter | null;
  extendedResultData?: JsonNullableFilter | null;
  nickName?: StringNullableFilter | null;
  userId?: StringFilter | null;
  createdBy?: UserWhereInput | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  modifiedBy?: StringNullableFilter | null;
}

export interface PollWhereUniqueInput {
  id?: string | null;
}

export interface PostCreateInput {
  id?: string | null;
  content: string;
  isPublished?: boolean | null;
  isFavorite?: boolean | null;
  nickName?: string | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  modifiedBy?: string | null;
  room: RoomCreateNestedOneWithoutPostsInput;
  reactions?: PostReactionCreateNestedManyWithoutPostInput | null;
  comments?: CommentCreateNestedManyWithoutPostInput | null;
  createdBy: UserCreateNestedOneWithoutPostsInput;
}

export interface PostCreateManyCreatedByInput {
  id?: string | null;
  content: string;
  isPublished?: boolean | null;
  isFavorite?: boolean | null;
  roomId: string;
  nickName?: string | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  modifiedBy?: string | null;
}

export interface PostCreateManyCreatedByInputEnvelope {
  data?: PostCreateManyCreatedByInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface PostCreateManyRoomInput {
  id?: string | null;
  content: string;
  isPublished?: boolean | null;
  isFavorite?: boolean | null;
  nickName?: string | null;
  userId: string;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  modifiedBy?: string | null;
}

export interface PostCreateManyRoomInputEnvelope {
  data?: PostCreateManyRoomInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface PostCreateNestedManyWithoutCreatedByInput {
  create?: PostCreateWithoutCreatedByInput[] | null;
  connectOrCreate?: PostCreateOrConnectWithoutCreatedByInput[] | null;
  createMany?: PostCreateManyCreatedByInputEnvelope | null;
  connect?: PostWhereUniqueInput[] | null;
}

export interface PostCreateNestedManyWithoutRoomInput {
  create?: PostCreateWithoutRoomInput[] | null;
  connectOrCreate?: PostCreateOrConnectWithoutRoomInput[] | null;
  createMany?: PostCreateManyRoomInputEnvelope | null;
  connect?: PostWhereUniqueInput[] | null;
}

export interface PostCreateNestedOneWithoutCommentsInput {
  create?: PostCreateWithoutCommentsInput | null;
  connectOrCreate?: PostCreateOrConnectWithoutCommentsInput | null;
  connect?: PostWhereUniqueInput | null;
}

export interface PostCreateNestedOneWithoutReactionsInput {
  create?: PostCreateWithoutReactionsInput | null;
  connectOrCreate?: PostCreateOrConnectWithoutReactionsInput | null;
  connect?: PostWhereUniqueInput | null;
}

export interface PostCreateOrConnectWithoutCommentsInput {
  where: PostWhereUniqueInput;
  create: PostCreateWithoutCommentsInput;
}

export interface PostCreateOrConnectWithoutCreatedByInput {
  where: PostWhereUniqueInput;
  create: PostCreateWithoutCreatedByInput;
}

export interface PostCreateOrConnectWithoutReactionsInput {
  where: PostWhereUniqueInput;
  create: PostCreateWithoutReactionsInput;
}

export interface PostCreateOrConnectWithoutRoomInput {
  where: PostWhereUniqueInput;
  create: PostCreateWithoutRoomInput;
}

export interface PostCreateWithoutCommentsInput {
  id?: string | null;
  content: string;
  isPublished?: boolean | null;
  isFavorite?: boolean | null;
  nickName?: string | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  modifiedBy?: string | null;
  room: RoomCreateNestedOneWithoutPostsInput;
  reactions?: PostReactionCreateNestedManyWithoutPostInput | null;
  createdBy: UserCreateNestedOneWithoutPostsInput;
}

export interface PostCreateWithoutCreatedByInput {
  id?: string | null;
  content: string;
  isPublished?: boolean | null;
  isFavorite?: boolean | null;
  nickName?: string | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  modifiedBy?: string | null;
  room: RoomCreateNestedOneWithoutPostsInput;
  reactions?: PostReactionCreateNestedManyWithoutPostInput | null;
  comments?: CommentCreateNestedManyWithoutPostInput | null;
}

export interface PostCreateWithoutReactionsInput {
  id?: string | null;
  content: string;
  isPublished?: boolean | null;
  isFavorite?: boolean | null;
  nickName?: string | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  modifiedBy?: string | null;
  room: RoomCreateNestedOneWithoutPostsInput;
  comments?: CommentCreateNestedManyWithoutPostInput | null;
  createdBy: UserCreateNestedOneWithoutPostsInput;
}

export interface PostCreateWithoutRoomInput {
  id?: string | null;
  content: string;
  isPublished?: boolean | null;
  isFavorite?: boolean | null;
  nickName?: string | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  modifiedBy?: string | null;
  reactions?: PostReactionCreateNestedManyWithoutPostInput | null;
  comments?: CommentCreateNestedManyWithoutPostInput | null;
  createdBy: UserCreateNestedOneWithoutPostsInput;
}

export interface PostListRelationFilter {
  every?: PostWhereInput | null;
  some?: PostWhereInput | null;
  none?: PostWhereInput | null;
}

export interface PostOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface PostOrderByWithRelationInput {
  id?: SortOrder | null;
  content?: SortOrder | null;
  isPublished?: SortOrder | null;
  isFavorite?: SortOrder | null;
  roomId?: SortOrder | null;
  room?: RoomOrderByWithRelationInput | null;
  reactions?: PostReactionOrderByRelationAggregateInput | null;
  comments?: CommentOrderByRelationAggregateInput | null;
  nickName?: SortOrder | null;
  userId?: SortOrder | null;
  createdBy?: UserOrderByWithRelationInput | null;
  createdAt?: SortOrder | null;
  updatedAt?: SortOrder | null;
  modifiedBy?: SortOrder | null;
}

export interface PostReactionCreateInput {
  id: string;
  reactionType?: ReactionType | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  modifiedBy?: string | null;
  post?: PostCreateNestedOneWithoutReactionsInput | null;
  createdBy: UserCreateNestedOneWithoutPostReactionsInput;
}

export interface PostReactionCreateManyCreatedByInput {
  id: string;
  reactionType?: ReactionType | null;
  postId: string;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  modifiedBy?: string | null;
}

export interface PostReactionCreateManyCreatedByInputEnvelope {
  data?: PostReactionCreateManyCreatedByInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface PostReactionCreateManyPostInput {
  id: string;
  reactionType?: ReactionType | null;
  userId: string;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  modifiedBy?: string | null;
}

export interface PostReactionCreateManyPostInputEnvelope {
  data?: PostReactionCreateManyPostInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface PostReactionCreateNestedManyWithoutCreatedByInput {
  create?: PostReactionCreateWithoutCreatedByInput[] | null;
  connectOrCreate?: PostReactionCreateOrConnectWithoutCreatedByInput[] | null;
  createMany?: PostReactionCreateManyCreatedByInputEnvelope | null;
  connect?: PostReactionWhereUniqueInput[] | null;
}

export interface PostReactionCreateNestedManyWithoutPostInput {
  create?: PostReactionCreateWithoutPostInput[] | null;
  connectOrCreate?: PostReactionCreateOrConnectWithoutPostInput[] | null;
  createMany?: PostReactionCreateManyPostInputEnvelope | null;
  connect?: PostReactionWhereUniqueInput[] | null;
}

export interface PostReactionCreateOrConnectWithoutCreatedByInput {
  where: PostReactionWhereUniqueInput;
  create: PostReactionCreateWithoutCreatedByInput;
}

export interface PostReactionCreateOrConnectWithoutPostInput {
  where: PostReactionWhereUniqueInput;
  create: PostReactionCreateWithoutPostInput;
}

export interface PostReactionCreateWithoutCreatedByInput {
  id: string;
  reactionType?: ReactionType | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  modifiedBy?: string | null;
  post?: PostCreateNestedOneWithoutReactionsInput | null;
}

export interface PostReactionCreateWithoutPostInput {
  id: string;
  reactionType?: ReactionType | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  modifiedBy?: string | null;
  createdBy: UserCreateNestedOneWithoutPostReactionsInput;
}

export interface PostReactionListRelationFilter {
  every?: PostReactionWhereInput | null;
  some?: PostReactionWhereInput | null;
  none?: PostReactionWhereInput | null;
}

export interface PostReactionOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface PostReactionScalarWhereInput {
  AND?: PostReactionScalarWhereInput[] | null;
  OR?: PostReactionScalarWhereInput[] | null;
  NOT?: PostReactionScalarWhereInput[] | null;
  id?: StringFilter | null;
  reactionType?: EnumReactionTypeFilter | null;
  postId?: StringFilter | null;
  userId?: StringFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  modifiedBy?: StringNullableFilter | null;
}

export interface PostReactionUpdateInput {
  id?: StringFieldUpdateOperationsInput | null;
  reactionType?: EnumReactionTypeFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  modifiedBy?: NullableStringFieldUpdateOperationsInput | null;
  post?: PostUpdateOneWithoutReactionsInput | null;
  createdBy?: UserUpdateOneRequiredWithoutPostReactionsInput | null;
}

export interface PostReactionUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  reactionType?: EnumReactionTypeFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  modifiedBy?: NullableStringFieldUpdateOperationsInput | null;
}

export interface PostReactionUpdateManyWithWhereWithoutCreatedByInput {
  where: PostReactionScalarWhereInput;
  data: PostReactionUpdateManyMutationInput;
}

export interface PostReactionUpdateManyWithWhereWithoutPostInput {
  where: PostReactionScalarWhereInput;
  data: PostReactionUpdateManyMutationInput;
}

export interface PostReactionUpdateManyWithoutCreatedByInput {
  create?: PostReactionCreateWithoutCreatedByInput[] | null;
  connectOrCreate?: PostReactionCreateOrConnectWithoutCreatedByInput[] | null;
  upsert?: PostReactionUpsertWithWhereUniqueWithoutCreatedByInput[] | null;
  createMany?: PostReactionCreateManyCreatedByInputEnvelope | null;
  connect?: PostReactionWhereUniqueInput[] | null;
  set?: PostReactionWhereUniqueInput[] | null;
  disconnect?: PostReactionWhereUniqueInput[] | null;
  delete?: PostReactionWhereUniqueInput[] | null;
  update?: PostReactionUpdateWithWhereUniqueWithoutCreatedByInput[] | null;
  updateMany?: PostReactionUpdateManyWithWhereWithoutCreatedByInput[] | null;
  deleteMany?: PostReactionScalarWhereInput[] | null;
}

export interface PostReactionUpdateManyWithoutPostInput {
  create?: PostReactionCreateWithoutPostInput[] | null;
  connectOrCreate?: PostReactionCreateOrConnectWithoutPostInput[] | null;
  upsert?: PostReactionUpsertWithWhereUniqueWithoutPostInput[] | null;
  createMany?: PostReactionCreateManyPostInputEnvelope | null;
  connect?: PostReactionWhereUniqueInput[] | null;
  set?: PostReactionWhereUniqueInput[] | null;
  disconnect?: PostReactionWhereUniqueInput[] | null;
  delete?: PostReactionWhereUniqueInput[] | null;
  update?: PostReactionUpdateWithWhereUniqueWithoutPostInput[] | null;
  updateMany?: PostReactionUpdateManyWithWhereWithoutPostInput[] | null;
  deleteMany?: PostReactionScalarWhereInput[] | null;
}

export interface PostReactionUpdateWithWhereUniqueWithoutCreatedByInput {
  where: PostReactionWhereUniqueInput;
  data: PostReactionUpdateWithoutCreatedByInput;
}

export interface PostReactionUpdateWithWhereUniqueWithoutPostInput {
  where: PostReactionWhereUniqueInput;
  data: PostReactionUpdateWithoutPostInput;
}

export interface PostReactionUpdateWithoutCreatedByInput {
  id?: StringFieldUpdateOperationsInput | null;
  reactionType?: EnumReactionTypeFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  modifiedBy?: NullableStringFieldUpdateOperationsInput | null;
  post?: PostUpdateOneWithoutReactionsInput | null;
}

export interface PostReactionUpdateWithoutPostInput {
  id?: StringFieldUpdateOperationsInput | null;
  reactionType?: EnumReactionTypeFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  modifiedBy?: NullableStringFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneRequiredWithoutPostReactionsInput | null;
}

export interface PostReactionUpsertWithWhereUniqueWithoutCreatedByInput {
  where: PostReactionWhereUniqueInput;
  update: PostReactionUpdateWithoutCreatedByInput;
  create: PostReactionCreateWithoutCreatedByInput;
}

export interface PostReactionUpsertWithWhereUniqueWithoutPostInput {
  where: PostReactionWhereUniqueInput;
  update: PostReactionUpdateWithoutPostInput;
  create: PostReactionCreateWithoutPostInput;
}

export interface PostReactionUserIdPostIdCompoundUniqueInput {
  userId: string;
  postId: string;
}

export interface PostReactionWhereInput {
  AND?: PostReactionWhereInput[] | null;
  OR?: PostReactionWhereInput[] | null;
  NOT?: PostReactionWhereInput[] | null;
  id?: StringFilter | null;
  reactionType?: EnumReactionTypeFilter | null;
  postId?: StringFilter | null;
  post?: PostWhereInput | null;
  userId?: StringFilter | null;
  createdBy?: UserWhereInput | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  modifiedBy?: StringNullableFilter | null;
}

export interface PostReactionWhereUniqueInput {
  id?: string | null;
  userId_postId?: PostReactionUserIdPostIdCompoundUniqueInput | null;
}

export interface PostScalarWhereInput {
  AND?: PostScalarWhereInput[] | null;
  OR?: PostScalarWhereInput[] | null;
  NOT?: PostScalarWhereInput[] | null;
  id?: StringFilter | null;
  content?: StringFilter | null;
  isPublished?: BoolFilter | null;
  isFavorite?: BoolFilter | null;
  roomId?: StringFilter | null;
  nickName?: StringNullableFilter | null;
  userId?: StringFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  modifiedBy?: StringNullableFilter | null;
}

export interface PostUpdateInput {
  id?: StringFieldUpdateOperationsInput | null;
  content?: StringFieldUpdateOperationsInput | null;
  isPublished?: BoolFieldUpdateOperationsInput | null;
  isFavorite?: BoolFieldUpdateOperationsInput | null;
  nickName?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  modifiedBy?: NullableStringFieldUpdateOperationsInput | null;
  room?: RoomUpdateOneRequiredWithoutPostsInput | null;
  reactions?: PostReactionUpdateManyWithoutPostInput | null;
  comments?: CommentUpdateManyWithoutPostInput | null;
  createdBy?: UserUpdateOneRequiredWithoutPostsInput | null;
}

export interface PostUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  content?: StringFieldUpdateOperationsInput | null;
  isPublished?: BoolFieldUpdateOperationsInput | null;
  isFavorite?: BoolFieldUpdateOperationsInput | null;
  nickName?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  modifiedBy?: NullableStringFieldUpdateOperationsInput | null;
}

export interface PostUpdateManyWithWhereWithoutCreatedByInput {
  where: PostScalarWhereInput;
  data: PostUpdateManyMutationInput;
}

export interface PostUpdateManyWithWhereWithoutRoomInput {
  where: PostScalarWhereInput;
  data: PostUpdateManyMutationInput;
}

export interface PostUpdateManyWithoutCreatedByInput {
  create?: PostCreateWithoutCreatedByInput[] | null;
  connectOrCreate?: PostCreateOrConnectWithoutCreatedByInput[] | null;
  upsert?: PostUpsertWithWhereUniqueWithoutCreatedByInput[] | null;
  createMany?: PostCreateManyCreatedByInputEnvelope | null;
  connect?: PostWhereUniqueInput[] | null;
  set?: PostWhereUniqueInput[] | null;
  disconnect?: PostWhereUniqueInput[] | null;
  delete?: PostWhereUniqueInput[] | null;
  update?: PostUpdateWithWhereUniqueWithoutCreatedByInput[] | null;
  updateMany?: PostUpdateManyWithWhereWithoutCreatedByInput[] | null;
  deleteMany?: PostScalarWhereInput[] | null;
}

export interface PostUpdateManyWithoutRoomInput {
  create?: PostCreateWithoutRoomInput[] | null;
  connectOrCreate?: PostCreateOrConnectWithoutRoomInput[] | null;
  upsert?: PostUpsertWithWhereUniqueWithoutRoomInput[] | null;
  createMany?: PostCreateManyRoomInputEnvelope | null;
  connect?: PostWhereUniqueInput[] | null;
  set?: PostWhereUniqueInput[] | null;
  disconnect?: PostWhereUniqueInput[] | null;
  delete?: PostWhereUniqueInput[] | null;
  update?: PostUpdateWithWhereUniqueWithoutRoomInput[] | null;
  updateMany?: PostUpdateManyWithWhereWithoutRoomInput[] | null;
  deleteMany?: PostScalarWhereInput[] | null;
}

export interface PostUpdateOneRequiredWithoutCommentsInput {
  create?: PostCreateWithoutCommentsInput | null;
  connectOrCreate?: PostCreateOrConnectWithoutCommentsInput | null;
  upsert?: PostUpsertWithoutCommentsInput | null;
  connect?: PostWhereUniqueInput | null;
  update?: PostUpdateWithoutCommentsInput | null;
}

export interface PostUpdateOneWithoutReactionsInput {
  create?: PostCreateWithoutReactionsInput | null;
  connectOrCreate?: PostCreateOrConnectWithoutReactionsInput | null;
  upsert?: PostUpsertWithoutReactionsInput | null;
  connect?: PostWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: PostUpdateWithoutReactionsInput | null;
}

export interface PostUpdateWithWhereUniqueWithoutCreatedByInput {
  where: PostWhereUniqueInput;
  data: PostUpdateWithoutCreatedByInput;
}

export interface PostUpdateWithWhereUniqueWithoutRoomInput {
  where: PostWhereUniqueInput;
  data: PostUpdateWithoutRoomInput;
}

export interface PostUpdateWithoutCommentsInput {
  id?: StringFieldUpdateOperationsInput | null;
  content?: StringFieldUpdateOperationsInput | null;
  isPublished?: BoolFieldUpdateOperationsInput | null;
  isFavorite?: BoolFieldUpdateOperationsInput | null;
  nickName?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  modifiedBy?: NullableStringFieldUpdateOperationsInput | null;
  room?: RoomUpdateOneRequiredWithoutPostsInput | null;
  reactions?: PostReactionUpdateManyWithoutPostInput | null;
  createdBy?: UserUpdateOneRequiredWithoutPostsInput | null;
}

export interface PostUpdateWithoutCreatedByInput {
  id?: StringFieldUpdateOperationsInput | null;
  content?: StringFieldUpdateOperationsInput | null;
  isPublished?: BoolFieldUpdateOperationsInput | null;
  isFavorite?: BoolFieldUpdateOperationsInput | null;
  nickName?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  modifiedBy?: NullableStringFieldUpdateOperationsInput | null;
  room?: RoomUpdateOneRequiredWithoutPostsInput | null;
  reactions?: PostReactionUpdateManyWithoutPostInput | null;
  comments?: CommentUpdateManyWithoutPostInput | null;
}

export interface PostUpdateWithoutReactionsInput {
  id?: StringFieldUpdateOperationsInput | null;
  content?: StringFieldUpdateOperationsInput | null;
  isPublished?: BoolFieldUpdateOperationsInput | null;
  isFavorite?: BoolFieldUpdateOperationsInput | null;
  nickName?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  modifiedBy?: NullableStringFieldUpdateOperationsInput | null;
  room?: RoomUpdateOneRequiredWithoutPostsInput | null;
  comments?: CommentUpdateManyWithoutPostInput | null;
  createdBy?: UserUpdateOneRequiredWithoutPostsInput | null;
}

export interface PostUpdateWithoutRoomInput {
  id?: StringFieldUpdateOperationsInput | null;
  content?: StringFieldUpdateOperationsInput | null;
  isPublished?: BoolFieldUpdateOperationsInput | null;
  isFavorite?: BoolFieldUpdateOperationsInput | null;
  nickName?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  modifiedBy?: NullableStringFieldUpdateOperationsInput | null;
  reactions?: PostReactionUpdateManyWithoutPostInput | null;
  comments?: CommentUpdateManyWithoutPostInput | null;
  createdBy?: UserUpdateOneRequiredWithoutPostsInput | null;
}

export interface PostUpsertWithWhereUniqueWithoutCreatedByInput {
  where: PostWhereUniqueInput;
  update: PostUpdateWithoutCreatedByInput;
  create: PostCreateWithoutCreatedByInput;
}

export interface PostUpsertWithWhereUniqueWithoutRoomInput {
  where: PostWhereUniqueInput;
  update: PostUpdateWithoutRoomInput;
  create: PostCreateWithoutRoomInput;
}

export interface PostUpsertWithoutCommentsInput {
  update: PostUpdateWithoutCommentsInput;
  create: PostCreateWithoutCommentsInput;
}

export interface PostUpsertWithoutReactionsInput {
  update: PostUpdateWithoutReactionsInput;
  create: PostCreateWithoutReactionsInput;
}

export interface PostWhereInput {
  AND?: PostWhereInput[] | null;
  OR?: PostWhereInput[] | null;
  NOT?: PostWhereInput[] | null;
  id?: StringFilter | null;
  content?: StringFilter | null;
  isPublished?: BoolFilter | null;
  isFavorite?: BoolFilter | null;
  roomId?: StringFilter | null;
  room?: RoomWhereInput | null;
  reactions?: PostReactionListRelationFilter | null;
  comments?: CommentListRelationFilter | null;
  nickName?: StringNullableFilter | null;
  userId?: StringFilter | null;
  createdBy?: UserWhereInput | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  modifiedBy?: StringNullableFilter | null;
}

export interface PostWhereUniqueInput {
  id?: string | null;
}

export interface RoomAttendeeCreateManyRoomInput {
  id: string;
  userId: string;
  nickName?: string | null;
  role?: AttendeeRole | null;
  joinKey: string;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  lastSeenAt?: SPEAKUPDateTime | null;
}

export interface RoomAttendeeCreateManyRoomInputEnvelope {
  data?: RoomAttendeeCreateManyRoomInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface RoomAttendeeCreateManyUserInput {
  id: string;
  roomId: string;
  nickName?: string | null;
  role?: AttendeeRole | null;
  joinKey: string;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  lastSeenAt?: SPEAKUPDateTime | null;
}

export interface RoomAttendeeCreateManyUserInputEnvelope {
  data?: RoomAttendeeCreateManyUserInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface RoomAttendeeCreateNestedManyWithoutRoomInput {
  create?: RoomAttendeeCreateWithoutRoomInput[] | null;
  connectOrCreate?: RoomAttendeeCreateOrConnectWithoutRoomInput[] | null;
  createMany?: RoomAttendeeCreateManyRoomInputEnvelope | null;
  connect?: RoomAttendeeWhereUniqueInput[] | null;
}

export interface RoomAttendeeCreateNestedManyWithoutUserInput {
  create?: RoomAttendeeCreateWithoutUserInput[] | null;
  connectOrCreate?: RoomAttendeeCreateOrConnectWithoutUserInput[] | null;
  createMany?: RoomAttendeeCreateManyUserInputEnvelope | null;
  connect?: RoomAttendeeWhereUniqueInput[] | null;
}

export interface RoomAttendeeCreateOrConnectWithoutRoomInput {
  where: RoomAttendeeWhereUniqueInput;
  create: RoomAttendeeCreateWithoutRoomInput;
}

export interface RoomAttendeeCreateOrConnectWithoutUserInput {
  where: RoomAttendeeWhereUniqueInput;
  create: RoomAttendeeCreateWithoutUserInput;
}

export interface RoomAttendeeCreateWithoutRoomInput {
  id: string;
  nickName?: string | null;
  role?: AttendeeRole | null;
  joinKey: string;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  lastSeenAt?: SPEAKUPDateTime | null;
  user: UserCreateNestedOneWithoutJoinedRoomsInput;
}

export interface RoomAttendeeCreateWithoutUserInput {
  id: string;
  nickName?: string | null;
  role?: AttendeeRole | null;
  joinKey: string;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  lastSeenAt?: SPEAKUPDateTime | null;
  room: RoomCreateNestedOneWithoutUsersInput;
}

export interface RoomAttendeeListRelationFilter {
  every?: RoomAttendeeWhereInput | null;
  some?: RoomAttendeeWhereInput | null;
  none?: RoomAttendeeWhereInput | null;
}

export interface RoomAttendeeOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface RoomAttendeeScalarWhereInput {
  AND?: RoomAttendeeScalarWhereInput[] | null;
  OR?: RoomAttendeeScalarWhereInput[] | null;
  NOT?: RoomAttendeeScalarWhereInput[] | null;
  id?: StringFilter | null;
  roomId?: StringFilter | null;
  userId?: StringFilter | null;
  nickName?: StringNullableFilter | null;
  role?: EnumAttendeeRoleFilter | null;
  joinKey?: StringFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  lastSeenAt?: DateTimeFilter | null;
}

export interface RoomAttendeeUpdateInput {
  id?: StringFieldUpdateOperationsInput | null;
  nickName?: NullableStringFieldUpdateOperationsInput | null;
  role?: EnumAttendeeRoleFieldUpdateOperationsInput | null;
  joinKey?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  lastSeenAt?: DateTimeFieldUpdateOperationsInput | null;
  user?: UserUpdateOneRequiredWithoutJoinedRoomsInput | null;
  room?: RoomUpdateOneRequiredWithoutUsersInput | null;
}

export interface RoomAttendeeUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  nickName?: NullableStringFieldUpdateOperationsInput | null;
  role?: EnumAttendeeRoleFieldUpdateOperationsInput | null;
  joinKey?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  lastSeenAt?: DateTimeFieldUpdateOperationsInput | null;
}

export interface RoomAttendeeUpdateManyWithWhereWithoutRoomInput {
  where: RoomAttendeeScalarWhereInput;
  data: RoomAttendeeUpdateManyMutationInput;
}

export interface RoomAttendeeUpdateManyWithWhereWithoutUserInput {
  where: RoomAttendeeScalarWhereInput;
  data: RoomAttendeeUpdateManyMutationInput;
}

export interface RoomAttendeeUpdateManyWithoutRoomInput {
  create?: RoomAttendeeCreateWithoutRoomInput[] | null;
  connectOrCreate?: RoomAttendeeCreateOrConnectWithoutRoomInput[] | null;
  upsert?: RoomAttendeeUpsertWithWhereUniqueWithoutRoomInput[] | null;
  createMany?: RoomAttendeeCreateManyRoomInputEnvelope | null;
  connect?: RoomAttendeeWhereUniqueInput[] | null;
  set?: RoomAttendeeWhereUniqueInput[] | null;
  disconnect?: RoomAttendeeWhereUniqueInput[] | null;
  delete?: RoomAttendeeWhereUniqueInput[] | null;
  update?: RoomAttendeeUpdateWithWhereUniqueWithoutRoomInput[] | null;
  updateMany?: RoomAttendeeUpdateManyWithWhereWithoutRoomInput[] | null;
  deleteMany?: RoomAttendeeScalarWhereInput[] | null;
}

export interface RoomAttendeeUpdateManyWithoutUserInput {
  create?: RoomAttendeeCreateWithoutUserInput[] | null;
  connectOrCreate?: RoomAttendeeCreateOrConnectWithoutUserInput[] | null;
  upsert?: RoomAttendeeUpsertWithWhereUniqueWithoutUserInput[] | null;
  createMany?: RoomAttendeeCreateManyUserInputEnvelope | null;
  connect?: RoomAttendeeWhereUniqueInput[] | null;
  set?: RoomAttendeeWhereUniqueInput[] | null;
  disconnect?: RoomAttendeeWhereUniqueInput[] | null;
  delete?: RoomAttendeeWhereUniqueInput[] | null;
  update?: RoomAttendeeUpdateWithWhereUniqueWithoutUserInput[] | null;
  updateMany?: RoomAttendeeUpdateManyWithWhereWithoutUserInput[] | null;
  deleteMany?: RoomAttendeeScalarWhereInput[] | null;
}

export interface RoomAttendeeUpdateWithWhereUniqueWithoutRoomInput {
  where: RoomAttendeeWhereUniqueInput;
  data: RoomAttendeeUpdateWithoutRoomInput;
}

export interface RoomAttendeeUpdateWithWhereUniqueWithoutUserInput {
  where: RoomAttendeeWhereUniqueInput;
  data: RoomAttendeeUpdateWithoutUserInput;
}

export interface RoomAttendeeUpdateWithoutRoomInput {
  id?: StringFieldUpdateOperationsInput | null;
  nickName?: NullableStringFieldUpdateOperationsInput | null;
  role?: EnumAttendeeRoleFieldUpdateOperationsInput | null;
  joinKey?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  lastSeenAt?: DateTimeFieldUpdateOperationsInput | null;
  user?: UserUpdateOneRequiredWithoutJoinedRoomsInput | null;
}

export interface RoomAttendeeUpdateWithoutUserInput {
  id?: StringFieldUpdateOperationsInput | null;
  nickName?: NullableStringFieldUpdateOperationsInput | null;
  role?: EnumAttendeeRoleFieldUpdateOperationsInput | null;
  joinKey?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  lastSeenAt?: DateTimeFieldUpdateOperationsInput | null;
  room?: RoomUpdateOneRequiredWithoutUsersInput | null;
}

export interface RoomAttendeeUpsertWithWhereUniqueWithoutRoomInput {
  where: RoomAttendeeWhereUniqueInput;
  update: RoomAttendeeUpdateWithoutRoomInput;
  create: RoomAttendeeCreateWithoutRoomInput;
}

export interface RoomAttendeeUpsertWithWhereUniqueWithoutUserInput {
  where: RoomAttendeeWhereUniqueInput;
  update: RoomAttendeeUpdateWithoutUserInput;
  create: RoomAttendeeCreateWithoutUserInput;
}

export interface RoomAttendeeUserIdRoomIdRoleCompoundUniqueInput {
  userId: string;
  roomId: string;
  role: AttendeeRole;
}

export interface RoomAttendeeWhereInput {
  AND?: RoomAttendeeWhereInput[] | null;
  OR?: RoomAttendeeWhereInput[] | null;
  NOT?: RoomAttendeeWhereInput[] | null;
  id?: StringFilter | null;
  roomId?: StringFilter | null;
  userId?: StringFilter | null;
  nickName?: StringNullableFilter | null;
  role?: EnumAttendeeRoleFilter | null;
  joinKey?: StringFilter | null;
  user?: UserWhereInput | null;
  room?: RoomWhereInput | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  lastSeenAt?: DateTimeFilter | null;
}

export interface RoomAttendeeWhereUniqueInput {
  id?: string | null;
  userId_roomId_role?: RoomAttendeeUserIdRoomIdRoleCompoundUniqueInput | null;
}

export interface RoomCreateInput {
  id?: string | null;
  name: string;
  adminKey?: string | null;
  num?: string | null;
  isTemporary?: boolean | null;
  isNicknames?: boolean | null;
  isModerated?: boolean | null;
  isPollOnly?: boolean | null;
  reCaptchaToken?: string | null;
  isCommentsAllowed?: boolean | null;
  isAutomaticFlow?: boolean | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  modifiedBy?: string | null;
  users?: RoomAttendeeCreateNestedManyWithoutRoomInput | null;
  posts?: PostCreateNestedManyWithoutRoomInput | null;
  polls?: PollCreateNestedManyWithoutRoomInput | null;
  createdBy: UserCreateNestedOneWithoutCreatedRoomsInput;
}

export interface RoomCreateManyCreatedByInput {
  id?: string | null;
  name: string;
  adminKey?: string | null;
  num?: string | null;
  isTemporary?: boolean | null;
  isNicknames?: boolean | null;
  isModerated?: boolean | null;
  isPollOnly?: boolean | null;
  reCaptchaToken?: string | null;
  isCommentsAllowed?: boolean | null;
  isAutomaticFlow?: boolean | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  modifiedBy?: string | null;
}

export interface RoomCreateManyCreatedByInputEnvelope {
  data?: RoomCreateManyCreatedByInput[] | null;
  skipDuplicates?: boolean | null;
}

export interface RoomCreateNestedManyWithoutCreatedByInput {
  create?: RoomCreateWithoutCreatedByInput[] | null;
  connectOrCreate?: RoomCreateOrConnectWithoutCreatedByInput[] | null;
  createMany?: RoomCreateManyCreatedByInputEnvelope | null;
  connect?: RoomWhereUniqueInput[] | null;
}

export interface RoomCreateNestedOneWithoutPollsInput {
  create?: RoomCreateWithoutPollsInput | null;
  connectOrCreate?: RoomCreateOrConnectWithoutPollsInput | null;
  connect?: RoomWhereUniqueInput | null;
}

export interface RoomCreateNestedOneWithoutPostsInput {
  create?: RoomCreateWithoutPostsInput | null;
  connectOrCreate?: RoomCreateOrConnectWithoutPostsInput | null;
  connect?: RoomWhereUniqueInput | null;
}

export interface RoomCreateNestedOneWithoutUsersInput {
  create?: RoomCreateWithoutUsersInput | null;
  connectOrCreate?: RoomCreateOrConnectWithoutUsersInput | null;
  connect?: RoomWhereUniqueInput | null;
}

export interface RoomCreateOrConnectWithoutCreatedByInput {
  where: RoomWhereUniqueInput;
  create: RoomCreateWithoutCreatedByInput;
}

export interface RoomCreateOrConnectWithoutPollsInput {
  where: RoomWhereUniqueInput;
  create: RoomCreateWithoutPollsInput;
}

export interface RoomCreateOrConnectWithoutPostsInput {
  where: RoomWhereUniqueInput;
  create: RoomCreateWithoutPostsInput;
}

export interface RoomCreateOrConnectWithoutUsersInput {
  where: RoomWhereUniqueInput;
  create: RoomCreateWithoutUsersInput;
}

export interface RoomCreateWithoutCreatedByInput {
  id?: string | null;
  name: string;
  adminKey?: string | null;
  num?: string | null;
  isTemporary?: boolean | null;
  isNicknames?: boolean | null;
  isModerated?: boolean | null;
  isPollOnly?: boolean | null;
  reCaptchaToken?: string | null;
  isCommentsAllowed?: boolean | null;
  isAutomaticFlow?: boolean | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  modifiedBy?: string | null;
  users?: RoomAttendeeCreateNestedManyWithoutRoomInput | null;
  posts?: PostCreateNestedManyWithoutRoomInput | null;
  polls?: PollCreateNestedManyWithoutRoomInput | null;
}

export interface RoomCreateWithoutPollsInput {
  id?: string | null;
  name: string;
  adminKey?: string | null;
  num?: string | null;
  isTemporary?: boolean | null;
  isNicknames?: boolean | null;
  isModerated?: boolean | null;
  isPollOnly?: boolean | null;
  reCaptchaToken?: string | null;
  isCommentsAllowed?: boolean | null;
  isAutomaticFlow?: boolean | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  modifiedBy?: string | null;
  users?: RoomAttendeeCreateNestedManyWithoutRoomInput | null;
  posts?: PostCreateNestedManyWithoutRoomInput | null;
  createdBy: UserCreateNestedOneWithoutCreatedRoomsInput;
}

export interface RoomCreateWithoutPostsInput {
  id?: string | null;
  name: string;
  adminKey?: string | null;
  num?: string | null;
  isTemporary?: boolean | null;
  isNicknames?: boolean | null;
  isModerated?: boolean | null;
  isPollOnly?: boolean | null;
  reCaptchaToken?: string | null;
  isCommentsAllowed?: boolean | null;
  isAutomaticFlow?: boolean | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  modifiedBy?: string | null;
  users?: RoomAttendeeCreateNestedManyWithoutRoomInput | null;
  polls?: PollCreateNestedManyWithoutRoomInput | null;
  createdBy: UserCreateNestedOneWithoutCreatedRoomsInput;
}

export interface RoomCreateWithoutUsersInput {
  id?: string | null;
  name: string;
  adminKey?: string | null;
  num?: string | null;
  isTemporary?: boolean | null;
  isNicknames?: boolean | null;
  isModerated?: boolean | null;
  isPollOnly?: boolean | null;
  reCaptchaToken?: string | null;
  isCommentsAllowed?: boolean | null;
  isAutomaticFlow?: boolean | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  modifiedBy?: string | null;
  posts?: PostCreateNestedManyWithoutRoomInput | null;
  polls?: PollCreateNestedManyWithoutRoomInput | null;
  createdBy: UserCreateNestedOneWithoutCreatedRoomsInput;
}

export interface RoomListRelationFilter {
  every?: RoomWhereInput | null;
  some?: RoomWhereInput | null;
  none?: RoomWhereInput | null;
}

export interface RoomOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface RoomOrderByWithRelationInput {
  id?: SortOrder | null;
  name?: SortOrder | null;
  adminKey?: SortOrder | null;
  num?: SortOrder | null;
  isTemporary?: SortOrder | null;
  isNicknames?: SortOrder | null;
  isModerated?: SortOrder | null;
  isPollOnly?: SortOrder | null;
  reCaptchaToken?: SortOrder | null;
  isCommentsAllowed?: SortOrder | null;
  isAutomaticFlow?: SortOrder | null;
  users?: RoomAttendeeOrderByRelationAggregateInput | null;
  posts?: PostOrderByRelationAggregateInput | null;
  polls?: PollOrderByRelationAggregateInput | null;
  userId?: SortOrder | null;
  createdBy?: UserOrderByWithRelationInput | null;
  createdAt?: SortOrder | null;
  updatedAt?: SortOrder | null;
  modifiedBy?: SortOrder | null;
}

export interface RoomScalarWhereInput {
  AND?: RoomScalarWhereInput[] | null;
  OR?: RoomScalarWhereInput[] | null;
  NOT?: RoomScalarWhereInput[] | null;
  id?: StringFilter | null;
  name?: StringFilter | null;
  adminKey?: StringNullableFilter | null;
  num?: StringNullableFilter | null;
  isTemporary?: BoolFilter | null;
  isNicknames?: BoolFilter | null;
  isModerated?: BoolFilter | null;
  isPollOnly?: BoolFilter | null;
  reCaptchaToken?: StringNullableFilter | null;
  isCommentsAllowed?: BoolFilter | null;
  isAutomaticFlow?: BoolFilter | null;
  userId?: StringFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  modifiedBy?: StringNullableFilter | null;
}

export interface RoomUpdateInput {
  id?: StringFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  adminKey?: NullableStringFieldUpdateOperationsInput | null;
  num?: NullableStringFieldUpdateOperationsInput | null;
  isTemporary?: BoolFieldUpdateOperationsInput | null;
  isNicknames?: BoolFieldUpdateOperationsInput | null;
  isModerated?: BoolFieldUpdateOperationsInput | null;
  isPollOnly?: BoolFieldUpdateOperationsInput | null;
  reCaptchaToken?: NullableStringFieldUpdateOperationsInput | null;
  isCommentsAllowed?: BoolFieldUpdateOperationsInput | null;
  isAutomaticFlow?: BoolFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  modifiedBy?: NullableStringFieldUpdateOperationsInput | null;
  users?: RoomAttendeeUpdateManyWithoutRoomInput | null;
  posts?: PostUpdateManyWithoutRoomInput | null;
  polls?: PollUpdateManyWithoutRoomInput | null;
  createdBy?: UserUpdateOneRequiredWithoutCreatedRoomsInput | null;
}

export interface RoomUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  adminKey?: NullableStringFieldUpdateOperationsInput | null;
  num?: NullableStringFieldUpdateOperationsInput | null;
  isTemporary?: BoolFieldUpdateOperationsInput | null;
  isNicknames?: BoolFieldUpdateOperationsInput | null;
  isModerated?: BoolFieldUpdateOperationsInput | null;
  isPollOnly?: BoolFieldUpdateOperationsInput | null;
  reCaptchaToken?: NullableStringFieldUpdateOperationsInput | null;
  isCommentsAllowed?: BoolFieldUpdateOperationsInput | null;
  isAutomaticFlow?: BoolFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  modifiedBy?: NullableStringFieldUpdateOperationsInput | null;
}

export interface RoomUpdateManyWithWhereWithoutCreatedByInput {
  where: RoomScalarWhereInput;
  data: RoomUpdateManyMutationInput;
}

export interface RoomUpdateManyWithoutCreatedByInput {
  create?: RoomCreateWithoutCreatedByInput[] | null;
  connectOrCreate?: RoomCreateOrConnectWithoutCreatedByInput[] | null;
  upsert?: RoomUpsertWithWhereUniqueWithoutCreatedByInput[] | null;
  createMany?: RoomCreateManyCreatedByInputEnvelope | null;
  connect?: RoomWhereUniqueInput[] | null;
  set?: RoomWhereUniqueInput[] | null;
  disconnect?: RoomWhereUniqueInput[] | null;
  delete?: RoomWhereUniqueInput[] | null;
  update?: RoomUpdateWithWhereUniqueWithoutCreatedByInput[] | null;
  updateMany?: RoomUpdateManyWithWhereWithoutCreatedByInput[] | null;
  deleteMany?: RoomScalarWhereInput[] | null;
}

export interface RoomUpdateOneRequiredWithoutPollsInput {
  create?: RoomCreateWithoutPollsInput | null;
  connectOrCreate?: RoomCreateOrConnectWithoutPollsInput | null;
  upsert?: RoomUpsertWithoutPollsInput | null;
  connect?: RoomWhereUniqueInput | null;
  update?: RoomUpdateWithoutPollsInput | null;
}

export interface RoomUpdateOneRequiredWithoutPostsInput {
  create?: RoomCreateWithoutPostsInput | null;
  connectOrCreate?: RoomCreateOrConnectWithoutPostsInput | null;
  upsert?: RoomUpsertWithoutPostsInput | null;
  connect?: RoomWhereUniqueInput | null;
  update?: RoomUpdateWithoutPostsInput | null;
}

export interface RoomUpdateOneRequiredWithoutUsersInput {
  create?: RoomCreateWithoutUsersInput | null;
  connectOrCreate?: RoomCreateOrConnectWithoutUsersInput | null;
  upsert?: RoomUpsertWithoutUsersInput | null;
  connect?: RoomWhereUniqueInput | null;
  update?: RoomUpdateWithoutUsersInput | null;
}

export interface RoomUpdateWithWhereUniqueWithoutCreatedByInput {
  where: RoomWhereUniqueInput;
  data: RoomUpdateWithoutCreatedByInput;
}

export interface RoomUpdateWithoutCreatedByInput {
  id?: StringFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  adminKey?: NullableStringFieldUpdateOperationsInput | null;
  num?: NullableStringFieldUpdateOperationsInput | null;
  isTemporary?: BoolFieldUpdateOperationsInput | null;
  isNicknames?: BoolFieldUpdateOperationsInput | null;
  isModerated?: BoolFieldUpdateOperationsInput | null;
  isPollOnly?: BoolFieldUpdateOperationsInput | null;
  reCaptchaToken?: NullableStringFieldUpdateOperationsInput | null;
  isCommentsAllowed?: BoolFieldUpdateOperationsInput | null;
  isAutomaticFlow?: BoolFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  modifiedBy?: NullableStringFieldUpdateOperationsInput | null;
  users?: RoomAttendeeUpdateManyWithoutRoomInput | null;
  posts?: PostUpdateManyWithoutRoomInput | null;
  polls?: PollUpdateManyWithoutRoomInput | null;
}

export interface RoomUpdateWithoutPollsInput {
  id?: StringFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  adminKey?: NullableStringFieldUpdateOperationsInput | null;
  num?: NullableStringFieldUpdateOperationsInput | null;
  isTemporary?: BoolFieldUpdateOperationsInput | null;
  isNicknames?: BoolFieldUpdateOperationsInput | null;
  isModerated?: BoolFieldUpdateOperationsInput | null;
  isPollOnly?: BoolFieldUpdateOperationsInput | null;
  reCaptchaToken?: NullableStringFieldUpdateOperationsInput | null;
  isCommentsAllowed?: BoolFieldUpdateOperationsInput | null;
  isAutomaticFlow?: BoolFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  modifiedBy?: NullableStringFieldUpdateOperationsInput | null;
  users?: RoomAttendeeUpdateManyWithoutRoomInput | null;
  posts?: PostUpdateManyWithoutRoomInput | null;
  createdBy?: UserUpdateOneRequiredWithoutCreatedRoomsInput | null;
}

export interface RoomUpdateWithoutPostsInput {
  id?: StringFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  adminKey?: NullableStringFieldUpdateOperationsInput | null;
  num?: NullableStringFieldUpdateOperationsInput | null;
  isTemporary?: BoolFieldUpdateOperationsInput | null;
  isNicknames?: BoolFieldUpdateOperationsInput | null;
  isModerated?: BoolFieldUpdateOperationsInput | null;
  isPollOnly?: BoolFieldUpdateOperationsInput | null;
  reCaptchaToken?: NullableStringFieldUpdateOperationsInput | null;
  isCommentsAllowed?: BoolFieldUpdateOperationsInput | null;
  isAutomaticFlow?: BoolFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  modifiedBy?: NullableStringFieldUpdateOperationsInput | null;
  users?: RoomAttendeeUpdateManyWithoutRoomInput | null;
  polls?: PollUpdateManyWithoutRoomInput | null;
  createdBy?: UserUpdateOneRequiredWithoutCreatedRoomsInput | null;
}

export interface RoomUpdateWithoutUsersInput {
  id?: StringFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  adminKey?: NullableStringFieldUpdateOperationsInput | null;
  num?: NullableStringFieldUpdateOperationsInput | null;
  isTemporary?: BoolFieldUpdateOperationsInput | null;
  isNicknames?: BoolFieldUpdateOperationsInput | null;
  isModerated?: BoolFieldUpdateOperationsInput | null;
  isPollOnly?: BoolFieldUpdateOperationsInput | null;
  reCaptchaToken?: NullableStringFieldUpdateOperationsInput | null;
  isCommentsAllowed?: BoolFieldUpdateOperationsInput | null;
  isAutomaticFlow?: BoolFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  modifiedBy?: NullableStringFieldUpdateOperationsInput | null;
  posts?: PostUpdateManyWithoutRoomInput | null;
  polls?: PollUpdateManyWithoutRoomInput | null;
  createdBy?: UserUpdateOneRequiredWithoutCreatedRoomsInput | null;
}

export interface RoomUpsertWithWhereUniqueWithoutCreatedByInput {
  where: RoomWhereUniqueInput;
  update: RoomUpdateWithoutCreatedByInput;
  create: RoomCreateWithoutCreatedByInput;
}

export interface RoomUpsertWithoutPollsInput {
  update: RoomUpdateWithoutPollsInput;
  create: RoomCreateWithoutPollsInput;
}

export interface RoomUpsertWithoutPostsInput {
  update: RoomUpdateWithoutPostsInput;
  create: RoomCreateWithoutPostsInput;
}

export interface RoomUpsertWithoutUsersInput {
  update: RoomUpdateWithoutUsersInput;
  create: RoomCreateWithoutUsersInput;
}

export interface RoomWhereInput {
  AND?: RoomWhereInput[] | null;
  OR?: RoomWhereInput[] | null;
  NOT?: RoomWhereInput[] | null;
  id?: StringFilter | null;
  name?: StringFilter | null;
  adminKey?: StringNullableFilter | null;
  num?: StringNullableFilter | null;
  isTemporary?: BoolFilter | null;
  isNicknames?: BoolFilter | null;
  isModerated?: BoolFilter | null;
  isPollOnly?: BoolFilter | null;
  reCaptchaToken?: StringNullableFilter | null;
  isCommentsAllowed?: BoolFilter | null;
  isAutomaticFlow?: BoolFilter | null;
  users?: RoomAttendeeListRelationFilter | null;
  posts?: PostListRelationFilter | null;
  polls?: PollListRelationFilter | null;
  userId?: StringFilter | null;
  createdBy?: UserWhereInput | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  modifiedBy?: StringNullableFilter | null;
}

export interface RoomWhereUniqueInput {
  id?: string | null;
  adminKey?: string | null;
  num?: string | null;
}

export interface StringFieldUpdateOperationsInput {
  set?: string | null;
}

export interface StringFilter {
  equals?: string | null;
  in?: string[] | null;
  notIn?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: QueryMode | null;
  not?: NestedStringFilter | null;
}

export interface StringNullableFilter {
  equals?: string | null;
  in?: string[] | null;
  notIn?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: QueryMode | null;
  not?: NestedStringNullableFilter | null;
}

export interface UserCreateNestedOneWithoutCommentReactionsInput {
  create?: UserCreateWithoutCommentReactionsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutCommentReactionsInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateNestedOneWithoutCommentsInput {
  create?: UserCreateWithoutCommentsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutCommentsInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateNestedOneWithoutCreatedRoomsInput {
  create?: UserCreateWithoutCreatedRoomsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutCreatedRoomsInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateNestedOneWithoutJoinedRoomsInput {
  create?: UserCreateWithoutJoinedRoomsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutJoinedRoomsInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateNestedOneWithoutPollResultsInput {
  create?: UserCreateWithoutPollResultsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutPollResultsInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateNestedOneWithoutPollsInput {
  create?: UserCreateWithoutPollsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutPollsInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateNestedOneWithoutPostReactionsInput {
  create?: UserCreateWithoutPostReactionsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutPostReactionsInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateNestedOneWithoutPostsInput {
  create?: UserCreateWithoutPostsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutPostsInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateOrConnectWithoutCommentReactionsInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutCommentReactionsInput;
}

export interface UserCreateOrConnectWithoutCommentsInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutCommentsInput;
}

export interface UserCreateOrConnectWithoutCreatedRoomsInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutCreatedRoomsInput;
}

export interface UserCreateOrConnectWithoutJoinedRoomsInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutJoinedRoomsInput;
}

export interface UserCreateOrConnectWithoutPollResultsInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutPollResultsInput;
}

export interface UserCreateOrConnectWithoutPollsInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutPollsInput;
}

export interface UserCreateOrConnectWithoutPostReactionsInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutPostReactionsInput;
}

export interface UserCreateOrConnectWithoutPostsInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutPostsInput;
}

export interface UserCreateWithoutCommentReactionsInput {
  id?: string | null;
  profileId?: string | null;
  language?: Language | null;
  theme?: Theme | null;
  flag?: string | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  createdRooms?: RoomCreateNestedManyWithoutCreatedByInput | null;
  joinedRooms?: RoomAttendeeCreateNestedManyWithoutUserInput | null;
  posts?: PostCreateNestedManyWithoutCreatedByInput | null;
  postReactions?: PostReactionCreateNestedManyWithoutCreatedByInput | null;
  comments?: CommentCreateNestedManyWithoutCreatedByInput | null;
  polls?: PollCreateNestedManyWithoutCreatedByInput | null;
  pollResults?: PollOptionResultCreateNestedManyWithoutCreatedByInput | null;
}

export interface UserCreateWithoutCommentsInput {
  id?: string | null;
  profileId?: string | null;
  language?: Language | null;
  theme?: Theme | null;
  flag?: string | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  createdRooms?: RoomCreateNestedManyWithoutCreatedByInput | null;
  joinedRooms?: RoomAttendeeCreateNestedManyWithoutUserInput | null;
  posts?: PostCreateNestedManyWithoutCreatedByInput | null;
  postReactions?: PostReactionCreateNestedManyWithoutCreatedByInput | null;
  commentReactions?: CommentReactionCreateNestedManyWithoutCreatedByInput | null;
  polls?: PollCreateNestedManyWithoutCreatedByInput | null;
  pollResults?: PollOptionResultCreateNestedManyWithoutCreatedByInput | null;
}

export interface UserCreateWithoutCreatedRoomsInput {
  id?: string | null;
  profileId?: string | null;
  language?: Language | null;
  theme?: Theme | null;
  flag?: string | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  joinedRooms?: RoomAttendeeCreateNestedManyWithoutUserInput | null;
  posts?: PostCreateNestedManyWithoutCreatedByInput | null;
  postReactions?: PostReactionCreateNestedManyWithoutCreatedByInput | null;
  comments?: CommentCreateNestedManyWithoutCreatedByInput | null;
  commentReactions?: CommentReactionCreateNestedManyWithoutCreatedByInput | null;
  polls?: PollCreateNestedManyWithoutCreatedByInput | null;
  pollResults?: PollOptionResultCreateNestedManyWithoutCreatedByInput | null;
}

export interface UserCreateWithoutJoinedRoomsInput {
  id?: string | null;
  profileId?: string | null;
  language?: Language | null;
  theme?: Theme | null;
  flag?: string | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  createdRooms?: RoomCreateNestedManyWithoutCreatedByInput | null;
  posts?: PostCreateNestedManyWithoutCreatedByInput | null;
  postReactions?: PostReactionCreateNestedManyWithoutCreatedByInput | null;
  comments?: CommentCreateNestedManyWithoutCreatedByInput | null;
  commentReactions?: CommentReactionCreateNestedManyWithoutCreatedByInput | null;
  polls?: PollCreateNestedManyWithoutCreatedByInput | null;
  pollResults?: PollOptionResultCreateNestedManyWithoutCreatedByInput | null;
}

export interface UserCreateWithoutPollResultsInput {
  id?: string | null;
  profileId?: string | null;
  language?: Language | null;
  theme?: Theme | null;
  flag?: string | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  createdRooms?: RoomCreateNestedManyWithoutCreatedByInput | null;
  joinedRooms?: RoomAttendeeCreateNestedManyWithoutUserInput | null;
  posts?: PostCreateNestedManyWithoutCreatedByInput | null;
  postReactions?: PostReactionCreateNestedManyWithoutCreatedByInput | null;
  comments?: CommentCreateNestedManyWithoutCreatedByInput | null;
  commentReactions?: CommentReactionCreateNestedManyWithoutCreatedByInput | null;
  polls?: PollCreateNestedManyWithoutCreatedByInput | null;
}

export interface UserCreateWithoutPollsInput {
  id?: string | null;
  profileId?: string | null;
  language?: Language | null;
  theme?: Theme | null;
  flag?: string | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  createdRooms?: RoomCreateNestedManyWithoutCreatedByInput | null;
  joinedRooms?: RoomAttendeeCreateNestedManyWithoutUserInput | null;
  posts?: PostCreateNestedManyWithoutCreatedByInput | null;
  postReactions?: PostReactionCreateNestedManyWithoutCreatedByInput | null;
  comments?: CommentCreateNestedManyWithoutCreatedByInput | null;
  commentReactions?: CommentReactionCreateNestedManyWithoutCreatedByInput | null;
  pollResults?: PollOptionResultCreateNestedManyWithoutCreatedByInput | null;
}

export interface UserCreateWithoutPostReactionsInput {
  id?: string | null;
  profileId?: string | null;
  language?: Language | null;
  theme?: Theme | null;
  flag?: string | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  createdRooms?: RoomCreateNestedManyWithoutCreatedByInput | null;
  joinedRooms?: RoomAttendeeCreateNestedManyWithoutUserInput | null;
  posts?: PostCreateNestedManyWithoutCreatedByInput | null;
  comments?: CommentCreateNestedManyWithoutCreatedByInput | null;
  commentReactions?: CommentReactionCreateNestedManyWithoutCreatedByInput | null;
  polls?: PollCreateNestedManyWithoutCreatedByInput | null;
  pollResults?: PollOptionResultCreateNestedManyWithoutCreatedByInput | null;
}

export interface UserCreateWithoutPostsInput {
  id?: string | null;
  profileId?: string | null;
  language?: Language | null;
  theme?: Theme | null;
  flag?: string | null;
  createdAt?: SPEAKUPDateTime | null;
  updatedAt?: SPEAKUPDateTime | null;
  createdRooms?: RoomCreateNestedManyWithoutCreatedByInput | null;
  joinedRooms?: RoomAttendeeCreateNestedManyWithoutUserInput | null;
  postReactions?: PostReactionCreateNestedManyWithoutCreatedByInput | null;
  comments?: CommentCreateNestedManyWithoutCreatedByInput | null;
  commentReactions?: CommentReactionCreateNestedManyWithoutCreatedByInput | null;
  polls?: PollCreateNestedManyWithoutCreatedByInput | null;
  pollResults?: PollOptionResultCreateNestedManyWithoutCreatedByInput | null;
}

export interface UserOrderByWithRelationInput {
  id?: SortOrder | null;
  profileId?: SortOrder | null;
  language?: SortOrder | null;
  theme?: SortOrder | null;
  flag?: SortOrder | null;
  createdRooms?: RoomOrderByRelationAggregateInput | null;
  joinedRooms?: RoomAttendeeOrderByRelationAggregateInput | null;
  posts?: PostOrderByRelationAggregateInput | null;
  postReactions?: PostReactionOrderByRelationAggregateInput | null;
  comments?: CommentOrderByRelationAggregateInput | null;
  commentReactions?: CommentReactionOrderByRelationAggregateInput | null;
  polls?: PollOrderByRelationAggregateInput | null;
  pollResults?: PollOptionResultOrderByRelationAggregateInput | null;
  createdAt?: SortOrder | null;
  updatedAt?: SortOrder | null;
}

export interface UserUpdateInput {
  id?: StringFieldUpdateOperationsInput | null;
  profileId?: StringFieldUpdateOperationsInput | null;
  language?: EnumLanguageFieldUpdateOperationsInput | null;
  theme?: EnumThemeFieldUpdateOperationsInput | null;
  flag?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  createdRooms?: RoomUpdateManyWithoutCreatedByInput | null;
  joinedRooms?: RoomAttendeeUpdateManyWithoutUserInput | null;
  posts?: PostUpdateManyWithoutCreatedByInput | null;
  postReactions?: PostReactionUpdateManyWithoutCreatedByInput | null;
  comments?: CommentUpdateManyWithoutCreatedByInput | null;
  commentReactions?: CommentReactionUpdateManyWithoutCreatedByInput | null;
  polls?: PollUpdateManyWithoutCreatedByInput | null;
  pollResults?: PollOptionResultUpdateManyWithoutCreatedByInput | null;
}

export interface UserUpdateOneRequiredWithoutCommentReactionsInput {
  create?: UserCreateWithoutCommentReactionsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutCommentReactionsInput | null;
  upsert?: UserUpsertWithoutCommentReactionsInput | null;
  connect?: UserWhereUniqueInput | null;
  update?: UserUpdateWithoutCommentReactionsInput | null;
}

export interface UserUpdateOneRequiredWithoutCommentsInput {
  create?: UserCreateWithoutCommentsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutCommentsInput | null;
  upsert?: UserUpsertWithoutCommentsInput | null;
  connect?: UserWhereUniqueInput | null;
  update?: UserUpdateWithoutCommentsInput | null;
}

export interface UserUpdateOneRequiredWithoutCreatedRoomsInput {
  create?: UserCreateWithoutCreatedRoomsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutCreatedRoomsInput | null;
  upsert?: UserUpsertWithoutCreatedRoomsInput | null;
  connect?: UserWhereUniqueInput | null;
  update?: UserUpdateWithoutCreatedRoomsInput | null;
}

export interface UserUpdateOneRequiredWithoutJoinedRoomsInput {
  create?: UserCreateWithoutJoinedRoomsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutJoinedRoomsInput | null;
  upsert?: UserUpsertWithoutJoinedRoomsInput | null;
  connect?: UserWhereUniqueInput | null;
  update?: UserUpdateWithoutJoinedRoomsInput | null;
}

export interface UserUpdateOneRequiredWithoutPollResultsInput {
  create?: UserCreateWithoutPollResultsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutPollResultsInput | null;
  upsert?: UserUpsertWithoutPollResultsInput | null;
  connect?: UserWhereUniqueInput | null;
  update?: UserUpdateWithoutPollResultsInput | null;
}

export interface UserUpdateOneRequiredWithoutPollsInput {
  create?: UserCreateWithoutPollsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutPollsInput | null;
  upsert?: UserUpsertWithoutPollsInput | null;
  connect?: UserWhereUniqueInput | null;
  update?: UserUpdateWithoutPollsInput | null;
}

export interface UserUpdateOneRequiredWithoutPostReactionsInput {
  create?: UserCreateWithoutPostReactionsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutPostReactionsInput | null;
  upsert?: UserUpsertWithoutPostReactionsInput | null;
  connect?: UserWhereUniqueInput | null;
  update?: UserUpdateWithoutPostReactionsInput | null;
}

export interface UserUpdateOneRequiredWithoutPostsInput {
  create?: UserCreateWithoutPostsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutPostsInput | null;
  upsert?: UserUpsertWithoutPostsInput | null;
  connect?: UserWhereUniqueInput | null;
  update?: UserUpdateWithoutPostsInput | null;
}

export interface UserUpdateWithoutCommentReactionsInput {
  id?: StringFieldUpdateOperationsInput | null;
  profileId?: StringFieldUpdateOperationsInput | null;
  language?: EnumLanguageFieldUpdateOperationsInput | null;
  theme?: EnumThemeFieldUpdateOperationsInput | null;
  flag?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  createdRooms?: RoomUpdateManyWithoutCreatedByInput | null;
  joinedRooms?: RoomAttendeeUpdateManyWithoutUserInput | null;
  posts?: PostUpdateManyWithoutCreatedByInput | null;
  postReactions?: PostReactionUpdateManyWithoutCreatedByInput | null;
  comments?: CommentUpdateManyWithoutCreatedByInput | null;
  polls?: PollUpdateManyWithoutCreatedByInput | null;
  pollResults?: PollOptionResultUpdateManyWithoutCreatedByInput | null;
}

export interface UserUpdateWithoutCommentsInput {
  id?: StringFieldUpdateOperationsInput | null;
  profileId?: StringFieldUpdateOperationsInput | null;
  language?: EnumLanguageFieldUpdateOperationsInput | null;
  theme?: EnumThemeFieldUpdateOperationsInput | null;
  flag?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  createdRooms?: RoomUpdateManyWithoutCreatedByInput | null;
  joinedRooms?: RoomAttendeeUpdateManyWithoutUserInput | null;
  posts?: PostUpdateManyWithoutCreatedByInput | null;
  postReactions?: PostReactionUpdateManyWithoutCreatedByInput | null;
  commentReactions?: CommentReactionUpdateManyWithoutCreatedByInput | null;
  polls?: PollUpdateManyWithoutCreatedByInput | null;
  pollResults?: PollOptionResultUpdateManyWithoutCreatedByInput | null;
}

export interface UserUpdateWithoutCreatedRoomsInput {
  id?: StringFieldUpdateOperationsInput | null;
  profileId?: StringFieldUpdateOperationsInput | null;
  language?: EnumLanguageFieldUpdateOperationsInput | null;
  theme?: EnumThemeFieldUpdateOperationsInput | null;
  flag?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  joinedRooms?: RoomAttendeeUpdateManyWithoutUserInput | null;
  posts?: PostUpdateManyWithoutCreatedByInput | null;
  postReactions?: PostReactionUpdateManyWithoutCreatedByInput | null;
  comments?: CommentUpdateManyWithoutCreatedByInput | null;
  commentReactions?: CommentReactionUpdateManyWithoutCreatedByInput | null;
  polls?: PollUpdateManyWithoutCreatedByInput | null;
  pollResults?: PollOptionResultUpdateManyWithoutCreatedByInput | null;
}

export interface UserUpdateWithoutJoinedRoomsInput {
  id?: StringFieldUpdateOperationsInput | null;
  profileId?: StringFieldUpdateOperationsInput | null;
  language?: EnumLanguageFieldUpdateOperationsInput | null;
  theme?: EnumThemeFieldUpdateOperationsInput | null;
  flag?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  createdRooms?: RoomUpdateManyWithoutCreatedByInput | null;
  posts?: PostUpdateManyWithoutCreatedByInput | null;
  postReactions?: PostReactionUpdateManyWithoutCreatedByInput | null;
  comments?: CommentUpdateManyWithoutCreatedByInput | null;
  commentReactions?: CommentReactionUpdateManyWithoutCreatedByInput | null;
  polls?: PollUpdateManyWithoutCreatedByInput | null;
  pollResults?: PollOptionResultUpdateManyWithoutCreatedByInput | null;
}

export interface UserUpdateWithoutPollResultsInput {
  id?: StringFieldUpdateOperationsInput | null;
  profileId?: StringFieldUpdateOperationsInput | null;
  language?: EnumLanguageFieldUpdateOperationsInput | null;
  theme?: EnumThemeFieldUpdateOperationsInput | null;
  flag?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  createdRooms?: RoomUpdateManyWithoutCreatedByInput | null;
  joinedRooms?: RoomAttendeeUpdateManyWithoutUserInput | null;
  posts?: PostUpdateManyWithoutCreatedByInput | null;
  postReactions?: PostReactionUpdateManyWithoutCreatedByInput | null;
  comments?: CommentUpdateManyWithoutCreatedByInput | null;
  commentReactions?: CommentReactionUpdateManyWithoutCreatedByInput | null;
  polls?: PollUpdateManyWithoutCreatedByInput | null;
}

export interface UserUpdateWithoutPollsInput {
  id?: StringFieldUpdateOperationsInput | null;
  profileId?: StringFieldUpdateOperationsInput | null;
  language?: EnumLanguageFieldUpdateOperationsInput | null;
  theme?: EnumThemeFieldUpdateOperationsInput | null;
  flag?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  createdRooms?: RoomUpdateManyWithoutCreatedByInput | null;
  joinedRooms?: RoomAttendeeUpdateManyWithoutUserInput | null;
  posts?: PostUpdateManyWithoutCreatedByInput | null;
  postReactions?: PostReactionUpdateManyWithoutCreatedByInput | null;
  comments?: CommentUpdateManyWithoutCreatedByInput | null;
  commentReactions?: CommentReactionUpdateManyWithoutCreatedByInput | null;
  pollResults?: PollOptionResultUpdateManyWithoutCreatedByInput | null;
}

export interface UserUpdateWithoutPostReactionsInput {
  id?: StringFieldUpdateOperationsInput | null;
  profileId?: StringFieldUpdateOperationsInput | null;
  language?: EnumLanguageFieldUpdateOperationsInput | null;
  theme?: EnumThemeFieldUpdateOperationsInput | null;
  flag?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  createdRooms?: RoomUpdateManyWithoutCreatedByInput | null;
  joinedRooms?: RoomAttendeeUpdateManyWithoutUserInput | null;
  posts?: PostUpdateManyWithoutCreatedByInput | null;
  comments?: CommentUpdateManyWithoutCreatedByInput | null;
  commentReactions?: CommentReactionUpdateManyWithoutCreatedByInput | null;
  polls?: PollUpdateManyWithoutCreatedByInput | null;
  pollResults?: PollOptionResultUpdateManyWithoutCreatedByInput | null;
}

export interface UserUpdateWithoutPostsInput {
  id?: StringFieldUpdateOperationsInput | null;
  profileId?: StringFieldUpdateOperationsInput | null;
  language?: EnumLanguageFieldUpdateOperationsInput | null;
  theme?: EnumThemeFieldUpdateOperationsInput | null;
  flag?: NullableStringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  createdRooms?: RoomUpdateManyWithoutCreatedByInput | null;
  joinedRooms?: RoomAttendeeUpdateManyWithoutUserInput | null;
  postReactions?: PostReactionUpdateManyWithoutCreatedByInput | null;
  comments?: CommentUpdateManyWithoutCreatedByInput | null;
  commentReactions?: CommentReactionUpdateManyWithoutCreatedByInput | null;
  polls?: PollUpdateManyWithoutCreatedByInput | null;
  pollResults?: PollOptionResultUpdateManyWithoutCreatedByInput | null;
}

export interface UserUpsertWithoutCommentReactionsInput {
  update: UserUpdateWithoutCommentReactionsInput;
  create: UserCreateWithoutCommentReactionsInput;
}

export interface UserUpsertWithoutCommentsInput {
  update: UserUpdateWithoutCommentsInput;
  create: UserCreateWithoutCommentsInput;
}

export interface UserUpsertWithoutCreatedRoomsInput {
  update: UserUpdateWithoutCreatedRoomsInput;
  create: UserCreateWithoutCreatedRoomsInput;
}

export interface UserUpsertWithoutJoinedRoomsInput {
  update: UserUpdateWithoutJoinedRoomsInput;
  create: UserCreateWithoutJoinedRoomsInput;
}

export interface UserUpsertWithoutPollResultsInput {
  update: UserUpdateWithoutPollResultsInput;
  create: UserCreateWithoutPollResultsInput;
}

export interface UserUpsertWithoutPollsInput {
  update: UserUpdateWithoutPollsInput;
  create: UserCreateWithoutPollsInput;
}

export interface UserUpsertWithoutPostReactionsInput {
  update: UserUpdateWithoutPostReactionsInput;
  create: UserCreateWithoutPostReactionsInput;
}

export interface UserUpsertWithoutPostsInput {
  update: UserUpdateWithoutPostsInput;
  create: UserCreateWithoutPostsInput;
}

export interface UserWhereInput {
  AND?: UserWhereInput[] | null;
  OR?: UserWhereInput[] | null;
  NOT?: UserWhereInput[] | null;
  id?: StringFilter | null;
  profileId?: StringFilter | null;
  language?: EnumLanguageFilter | null;
  theme?: EnumThemeFilter | null;
  flag?: StringNullableFilter | null;
  createdRooms?: RoomListRelationFilter | null;
  joinedRooms?: RoomAttendeeListRelationFilter | null;
  posts?: PostListRelationFilter | null;
  postReactions?: PostReactionListRelationFilter | null;
  comments?: CommentListRelationFilter | null;
  commentReactions?: CommentReactionListRelationFilter | null;
  polls?: PollListRelationFilter | null;
  pollResults?: PollOptionResultListRelationFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface UserWhereUniqueInput {
  id?: string | null;
  profileId?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
