import { useTranslation } from 'react-i18next'

const FlashNotification = () => {
    const { t } = useTranslation(['Room'])
    return (
        <div className="flash-notification ">
            <button className="button-submit" type="button">
                <span className="new-message-text">{t('FlashMessage')}</span>
            </button>
        </div>
    )
}

export default FlashNotification
