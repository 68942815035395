import 'animate.css'
import 'moment/locale/fr'
import 'moment/locale/de'
import 'moment/locale/es-us'

import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { I18nextProvider } from 'react-i18next'

import createClient from './apolloClient'
import AppRouter from './routers/AppRouter'

import i18n from './i18n'

function App() {
    return (
        <ApolloProvider client={createClient()}>
            <I18nextProvider i18n={i18n}>
                <BrowserRouter>
                    <AppRouter />
                </BrowserRouter>
            </I18nextProvider>
        </ApolloProvider>
    )
}

export default App
