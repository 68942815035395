import React from 'react'
import { JoinRoom } from '../../../generated/JoinRoom'
import { useTranslation } from 'react-i18next'

import { EventAction, EventCategory, gaEvent } from '../../common/utilis'

interface Props {
    data: JoinRoom
    leaveRoom: Function
}

const Settings: React.FC<Props> = ({ data, leaveRoom }) => {
    const { t } = useTranslation(['Room'])

    const handleLeaveRoom = async () => {
        const roomId = data.roomAttendee?.room.id
        const joinRole = data.roomAttendee?.role
        await leaveRoom(roomId, joinRole)
        gaEvent(
            EventCategory.Room,
            EventAction.Leave,
            data.roomAttendee?.room.name!
        )
    }

    return (
        <>
            <div className="container-information">
                <div className="entry-information">
                    <h2>{t('RoomInformation')}</h2>
                    <p>
                        {t('Number')}: {data.roomAttendee?.room.num}
                    </p>
                    {data.roomAttendee?.nickName && (
                        <p>
                            {t('Nickname')}: {data.roomAttendee?.nickName}
                        </p>
                    )}

                    <ul className="room-buttons">
                        {/**
                         *  <QRCode roomKey={data.roomAttendee?.room.num!} />
                         */}
                        <li
                            className="button button-leave"
                            onClick={handleLeaveRoom}
                        >
                            <p>{t('LeaveTheRoom')}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Settings
